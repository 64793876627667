import {LazyLoadEvent} from 'primeng/api';

export class Pagination {

  page: number = 0;
  size: number = 0;
  columns: string = '';
  direction: number = -1;
  filters = new Array<Filter>();


  constructor(event?: LazyLoadEvent) {

    event = event || {rows: 10, first: 0, filters: {}};

    this.size = event.rows;
    this.page = event.first / event.rows;


    this.columns = event.sortField;
    this.direction = event.sortOrder;

    this.filters = [];


    Object.keys(event.filters || [])
      .filter(k => k != 'global')
      .map(k => {
        const filter = event.filters[k] as any;

        if (filter instanceof Array)
          filter.forEach(f => this.filters.push(new Filter(k, f.value, f.matchMode, f.operator)));
        else
          this.filters.push(new Filter(k, filter.value, filter.matchMode, filter.operator))

      });

    if (event.globalFilter)
      this.filters.push(new Filter('global', event.globalFilter, 'contains', ''))


  }


  addDefaultFilter(filter: Filter) {
    if (this.filters.findIndex(f => f.value != null && f.field == filter.field) == -1)
      this.filters.push(filter);

  }


}


export class Filter {

  constructor(
    public field: string,
    public value: any,
    public matchMode: string,
    public operator: string,
  ) {

    if (value instanceof Date)
      this.value = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);

  }

}
