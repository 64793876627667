<mat-toolbar class="mat-elevation-z4">
  Actividades

  <div fxFlex></div>


  <button mat-icon-button routerLink="administrar" *ngIf="auth.writeActivities">
    <mat-icon svgIcon="cog-outline"></mat-icon>
  </button>
</mat-toolbar>


<div class="content" fxLayout="column" fxLayoutGap="24px">

  <mat-card fxFlex="100" *ngFor="let a of activities.content" class="mat-elevation-z3">

    <mat-card-header>
      <mat-card-title>
        {{a.name}}
      </mat-card-title>
      <mat-card-subtitle>
        <div>El {{a.date | date: 'dd/MM/yy'}} a las {{a.date | date: 'HH:mm'}}</div>
      </mat-card-subtitle>
    </mat-card-header>


    <mat-card-content>
      <div class="ck-content" [innerHTML]="a.description | safeHtml"></div>

      <mat-divider inset="true"></mat-divider>
      <div fxLayout="row wrap" fxLayoutGap="16px ">

        <div fxFlex></div>
        <span class="activity-data">
          <mat-icon svgIcon="account-group"></mat-icon>
          {{ a.registered }} / {{a.maxParticipants}}
        </span>

        <span class="activity-data" *ngIf="a.price > 0">
          <mat-icon svgIcon="cash"></mat-icon>{{ a.price | currency }}
        </span>
      </div>



      <mat-card-actions align="end" >
        <button mat-button *ngIf="a.payment == null" (click)="register($any(a))">
          <mat-icon svgIcon="draw"></mat-icon> Inscribirse
        </button>

        <ng-container *ngIf="a.payment?.status == 'UNPAID'">
          <button mat-button (click)="payActivity(a.payment.id)">
            <mat-icon svgIcon="credit-card"></mat-icon> Pagar
          </button>

        </ng-container>

        <button mat-button *ngIf="a.payment?.status == 'PAID'">
          <mat-icon svgIcon="cash"></mat-icon> Inscrito
        </button>


        <button mat-icon-button [matMenuTriggerFor]="menu"  *ngIf="a.payment || auth.writeActivities">
          <mat-icon svgIcon="dots-vertical"></mat-icon>
        </button>


        <mat-menu #menu="matMenu">
          <button mat-menu-item [disabled]="a.payment == null">
            <mat-icon svgIcon="close"></mat-icon>
            <span>Solicitar anulación</span>
          </button>
          <button mat-menu-item *ngIf="a.payment?.status == 'UNPAID'"  (click)="sendJustification($any(a))">
            <mat-icon svgIcon="email"></mat-icon>
            <span>Enviar justificante pago</span>
          </button>
          <button mat-menu-item *ngIf="auth.writeActivities" (click)="showRegistered($any(a))">
            <mat-icon svgIcon="account-group"></mat-icon>
            <span>Ver inscritos</span>
          </button>
        </mat-menu>




      </mat-card-actions>


    </mat-card-content>

  </mat-card>


</div>




<p-paginator [rows]="10" [totalRecords]="activities.totalElements" (onPageChange)="onLazyEvent($event)" ></p-paginator>

