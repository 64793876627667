import {Component, OnInit} from '@angular/core';
import {ActivitiesTableQuery, Activity, PageActivities} from "../../../generated/graphql";
import {PaginationList} from "../../utils/PaginationList";
import {AuthService} from "../../users/auth/auth.service";
import {ActivitiesService} from "../services/activities.service";
import {PaymentsService} from "../../payments/services/payments.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {ShowParticipantsComponent} from "../show-participants/show-participants.component";
import {Mailto, NgxMailtoService} from "ngx-mailto";

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent extends PaginationList implements OnInit {

  activities: ActivitiesTableQuery['activities'] = new PageActivities();


  constructor(
    public auth: AuthService,
    private activitiesService: ActivitiesService,
    private paymentService: PaymentsService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private mailtoService: NgxMailtoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateElements();
  }

  updateElements(): void {

    this.activitiesService
      .adminList(this.pagination)
      .subscribe(s => this.activities = s.data.activities);


  }

  random(maxParticipants: number) {
    return Math.floor(Math.random() * maxParticipants);
  }

  register(activity: Activity) {
    this.activitiesService
      .register(activity.id)
      .subscribe( _ => this.updateElements());
  }


  payActivity(id: string) {
    this.paymentService.pay(id)
      .subscribe( s => {
        if (s.data?.url)
          window.location.href = s.data.url;
        else
          this.updateElements();
      })
  }

  showRegistered(activity: Activity) {
    this.dialog.open(ShowParticipantsComponent, {
      width: '90%',
      data: {participants: activity.participants}
    });


  }

  sendJustification(activity: Activity) {

    const mailTo: Mailto =  {
      receiver: 'miguel.angel.lopez.vicente@gmail.com',
      subject:  `Justificante de pago ${activity.payment.id}`,
      body: `Adjunto el justificante de pago para la actividad ${activity.name} por el importe de ${activity.payment.amount} para su aprovación por parte del equipo pertinente.`
    }

    this.mailtoService.open(mailTo);

  }
}
