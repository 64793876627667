
<ng-container *ngIf="errors">

  <ul>
    <li *ngFor="let message of messages">
      <mat-error [innerHTML]="message"></mat-error>
    </li>
  </ul>



</ng-container>
