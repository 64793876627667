export class EditorConfig {
  static defaultConfig = {
    toolbar: {
      items: [
        'heading',
        'bold',
        'italic',
        'link',
        'alignment',
        'fontColor',
        'fontSize',
        '|',
        'horizontalLine',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
      ]
    },
    language: 'es',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
  };


}
