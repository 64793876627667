import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GraphQLError} from "graphql";

@Component({
  selector: 'app-graphql-errors',
  templateUrl: './graphql-errors.component.html',
  styleUrls: ['./graphql-errors.component.scss']
})
export class GraphqlErrorsComponent implements OnChanges {


  @Input()
  errors: readonly GraphQLError[];

  @Input()
  form: HTMLFormElement;

  messages = new Array<string>();

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.messages = [];

    if (!this.errors)
      return;


    const regex = new RegExp("Field '(.*)' of variable '(.*)' .*", 'ig');


    this.errors.forEach( m => {
      const matches = regex.exec(m.message)
        if (matches) {
          const field = matches[1];
          this.messages.push('El campo <b>' + this.translate(field) + '</b> no es válido.')
        } else {
          this.messages.push(m.message);
        }

    })


  }




  translate(field: string) {

    const element = this.form.querySelector(`[name=${field}]`)

    if (element == null)  return field;

    const label = element.parentElement.querySelector('label')

    if (label == null) return field;

    return label.innerText.replace(" *", "").toLowerCase();

  }


}
