<div class="content" fxLayout="row wrap" fxLayoutGap="16px grid">

  <div fxFlex="100">
    <mat-card class="mat-elevation-z3">

      <mat-card-header>
        <mat-card-title>
          {{post.title}}
        </mat-card-title>
        <mat-card-subtitle>
          <div>El {{post.published | date: 'dd/mm/yy'}} a las {{post.published | date: 'HH:mm'}}</div>
        </mat-card-subtitle>
      </mat-card-header>


      <mat-card-content>
        <div class="ck-content" [innerHTML]="post.text | safeHtml"></div>

        <div class="clear"></div>

        <div class="tags">

          <mat-chip-list>
            <mat-chip *ngFor="let tag of post.tags" [routerLink]="['/blog/', tag]">{{tag}}</mat-chip>
          </mat-chip-list>

        </div>


        <mat-divider inset="true"></mat-divider>


        <app-comment [comments]="post.comments">

        </app-comment>


      </mat-card-content>

    </mat-card>
  </div>


  <div fxFlex="100">
    <app-add-comment [post]="post.id" (commented)="update()"></app-add-comment>
  </div>

</div>



