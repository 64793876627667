<mat-toolbar class="mat-elevation-z4">
  Comedor

  <span fxFlex></span>

  <button mat-icon-button routerLink="administrar" *ngIf="auth.writeCanteen">
    <mat-icon svgIcon="cog-outline"></mat-icon>
  </button>


</mat-toolbar>


<div class="content">

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <button mat-icon-button (click)="setWeek(-1)">
          <mat-icon svgIcon="chevron-left" ></mat-icon>
        </button>
        Semana del {{start | date:'dd' }} al {{end | date: 'dd' }}

        <button mat-icon-button (click)="setWeek(1)">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
      </mat-card-title>
      <mat-card-subtitle>{{ start | date: "MMMM" | titlecase }} de {{ start | date:"yyyy"}}</mat-card-subtitle>


    </mat-card-header>

    <mat-card-content>
      <p-table [rows]="20"
               [lazy]="true"
               [paginator]="true"
               [value]="menus.content"
               [alwaysShowPaginator]="true"
               (onLazyLoad)="onLazyEvent($event)"
               [sortOrder]="1"
               sortField="date"
               responsiveLayout="scroll"

               [totalRecords]="menus.totalElements">


        <ng-template pTemplate="body" let-m let-rowgroup="rowgroup" let-rowspan="rowspan">
          <tr class="day">
            <td colspan="2">
              {{m.date | date:"EEEE" | titlecase}}
              {{m.date | date:"dd 'de' MMMM"}}

            </td>

          </tr>

          <tr *ngFor="let dish of m.dishes" class="dish">

            <td style="width: 100px"><b>{{dish.category | dishCategory}}</b></td>


            <td>
              {{dish.name}}
              <div class="mat-small">{{dish.notes}}</div>
            </td>
          </tr>
        </ng-template>


      </p-table>

    </mat-card-content>
  </mat-card>


</div>
