import {Component, OnInit} from '@angular/core';
import {PaymentsService} from "../services/payments.service";
import {CheckPaymentQuery} from "../../../generated/graphql";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.scss']
})
export class CheckPaymentComponent implements OnInit {


  payment: CheckPaymentQuery['payment'] = null;


  constructor(
    private paymentService: PaymentsService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {

    const id = this.router.snapshot.paramMap.get('id');

    this.paymentService
      .check(id)
      .subscribe( s => this.payment = s.data.payment )

  }

}
