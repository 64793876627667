import {Component} from '@angular/core';
import {LoginQueryService} from "../../../generated/graphql";
import {AuthService} from "../auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  error = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private loginService: LoginQueryService) {
  }


  send(value: any) {

    this.error = false;

    this.loginService.fetch(value)
      .subscribe(s => {

        if (s.errors) {
          this.error = true;
          return;
        }


        this.authService.newTokens(s.data.login);
        this.router.navigate(['/inicio']);

      });
  }
}
