<h2 mat-dialog-title>Actividad</h2>

<form #form="ngForm" #formElement>

  <mat-dialog-content>


    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <mat-form-field fxFlex="100">
        <input matInput maxlength="200" placeholder="Título" required name="name" [ngModel]="activity.name">
      </mat-form-field>

      <mat-form-field fxFlex="33">
        <input name="date" matInput [ngxMatDatetimePicker]="date" placeholder="Fecha" [ngModel]="activity.date" readonly (click)="date.open()">
        <mat-datepicker-toggle matSuffix [for]="$any(date)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #date [showSeconds]="false"></ngx-mat-datetime-picker>
      </mat-form-field>


      <mat-form-field fxFlex="33">

        <input matInput type="number" step="1" name="maxParticipants" [ngModel]="activity.maxParticipants" placeholder="Máximo de participantes" min="0">

      </mat-form-field>


      <mat-form-field fxFlex="33">
        <input matInput name="price" type="number" step="0.1" placeholder="Precio" min="0" [ngModel]="activity.price">
      </mat-form-field>


      <div fxFlex="100">
        <ckeditor (ready)="editorReady($event)" [editor]="editor" [config]="editorConfig"></ckeditor>
      </div>

    </div>


    <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  </mat-dialog-content>
</form>

<mat-dialog-actions>

  <button mat-raised-button color="primary" (click)="save(form.value)" [disabled]="form.invalid">Guardar</button>
  <button mat-button mat-dialog-close>Cerrar</button>

</mat-dialog-actions>
