import {Injectable} from '@angular/core';
import {
  GetMenuQueryService,
  MenuInput,
  SaveMenuMutationService,
  TableMenusQueryService
} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class CanteenService {

  constructor(
    private getService: GetMenuQueryService,
    private saveService: SaveMenuMutationService,
    private listService: TableMenusQueryService
  ) {

  }


  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }

  save(menu: MenuInput, id: string) {
    return this.saveService.mutate({menu, id});
  }

  get(id: string) {
    return this.getService.fetch({id});
  }


}
