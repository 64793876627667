
<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Menú comedor</span>

  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>

</mat-toolbar>


<div class="content">
  <mat-card class="mat-elevation-z4">



    <p-table [rows]="20"
             [lazy]="true"
             [paginator]="true"
             [value]="menus.content"
             [alwaysShowPaginator]="true"
             (onLazyLoad)="onLazyEvent($event)"
             [sortOrder]="1"
             sortField="date"
             responsiveLayout="scroll"
             [totalRecords]="menus.totalElements">


      <ng-template pTemplate="header">
        <tr>
          <th>Fecha</th>
          <th>Platos</th>
          <th style="width: 64px"></th>
        </tr>
      </ng-template>



      <ng-template pTemplate="body" let-m let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>
          <td>{{m.date | date:'dd/MM/yy'}}</td>

          <td>
            {{m | showDish}}
          </td>

          <td>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(m.id)">
                <mat-icon svgIcon="pencil"></mat-icon>
                <span>Editar</span>
              </button>

              <button mat-menu-item (click)="delete(m.id)">
                <mat-icon svgIcon="delete"></mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </td>
        </tr>
      </ng-template>

    </p-table>



  </mat-card>
</div>




