import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommentInput, SaveCommentMutationService} from "../../../generated/graphql";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {


  @Input()
  post: string

  @Output()
  commented = new EventEmitter();


  constructor(
    private commentService: SaveCommentMutationService
  ) {
  }

  ngOnInit(): void {


  }


  sendComment(form: NgForm) {

    const comment: CommentInput = form.value;

    this.commentService
      .mutate({post: this.post, comment})
      .subscribe( s => {
        form.resetForm();
        this.commented.emit();
      })
  }

}
