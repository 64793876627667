import {Component, Inject, OnInit} from '@angular/core';
import {Participant} from "../../../generated/graphql";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-show-participants',
  templateUrl: './show-participants.component.html',
  styleUrls: ['./show-participants.component.scss']
})
export class ShowParticipantsComponent implements OnInit {


  participants = new Array<Participant>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any
  ) {



    this.participants = data.participants;


  }

  ngOnInit(): void {

  }

}
