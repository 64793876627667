<mat-card *ngIf="payment" class="card-center">
  <mat-card-header>
    <mat-card-title>
      Pago: {{payment.name}}
    </mat-card-title>

    <mat-card-subtitle>
      Total: {{payment.amount | currency }}
    </mat-card-subtitle>
  </mat-card-header>


  <mat-card-content>

    <ng-container *ngIf="payment.status == 'PAID' || payment.status == 'PAID_MANUALLY'">
      <h2>
        <mat-icon svgIcon="check-outline"></mat-icon>
        Pago realizado
      </h2>
    </ng-container>


    <ng-container *ngIf="payment.status == 'UNPAID'">
      <h2>
        <mat-icon svgIcon="close-outline"></mat-icon>
        Pago no realizado
      </h2>
    </ng-container>


  </mat-card-content>


</mat-card>
