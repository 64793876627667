
<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Entradas del blog</span>

  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>

</mat-toolbar>


<div class="content">
  <mat-card class="mat-elevation-z4">


    <p-table [rows]="20"
             [lazy]="true"
             [paginator]="true"
             [value]="posts.content"
             [alwaysShowPaginator]="false"
             (onLazyLoad)="onLazyEvent($event)"
             [sortOrder]="1"
             sortField="id"
             [totalRecords]="posts.totalElements">


      <ng-template pTemplate="header">
        <tr>
          <th>Título</th>
          <th>Publicación</th>
          <th style="width: 64px"></th>
        </tr>
      </ng-template>


      <ng-template pTemplate="body" let-post>
        <tr>
          <td>{{post.title}}</td>
          <td>{{post.published | date: 'hh:mm dd/MM/yy'}}</td>
          <td>


            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(post.id)">
                <mat-icon svgIcon="pencil"></mat-icon>
                <span>Editar</span>
              </button>

              <button mat-menu-item (click)="delete(post.id)">
                <mat-icon svgIcon="delete"></mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </td>
        </tr>
      </ng-template>

    </p-table>


  </mat-card>
</div>



