<h2 mat-dialog-title>Administrador</h2>

<mat-dialog-content>

  <form #user="ngForm" #userElement>

    <ng-container *ngIf="administrator.user == null">

      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <mat-form-field fxFlex="66">
          <input matInput ngModel placeholder="Nombre de usuario" name="username" required/>
        </mat-form-field>

        <mat-form-field fxFlex="33">

          <mat-select ngModel placeholder="Crear un usuario nuevo" name="new" required>
            <mat-option [value]="true">Crear nuevo usuario</mat-option>
            <mat-option [value]="false">Usar existente</mat-option>

          </mat-select>

        </mat-form-field>

      </div>

      <mat-divider></mat-divider>

    </ng-container>

  </form>


  <form #form="ngForm" #formElement>
    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <mat-form-field fxFlex="33">
        <input matInput placeholder="Nombre" name="name" [ngModel]="administrator.name" required/>
      </mat-form-field>

      <mat-form-field fxFlex="66">
        <input matInput placeholder="Apellidos" name="lastName" [ngModel]="administrator.lastName" required/>
      </mat-form-field>

      <mat-form-field fxFlex="33">
        <input matInput placeholder="DNI" name="dni" [ngModel]="administrator.dni" required/>
      </mat-form-field>


      <mat-form-field fxFlex="33">
        <input matInput placeholder="Email" name="email" type="email" [ngModel]="administrator.email" required/>
      </mat-form-field>

      <mat-form-field fxFlex="33">
        <input matInput placeholder="Teléfono" name="phone" [ngModel]="administrator.phone" required>
      </mat-form-field>


      <mat-form-field fxFlex="33">
        <input matInput placeholder="Cargo" name="charge" [ngModel]="administrator.charge">
      </mat-form-field>

      <mat-form-field fxFlex="66">
        <mat-select multiple="true" name="roles" placeholder="Permisos" [ngModel]="administrator.roles"
                    [disabled]="administrator?.user?.id == auth.active.id">

          <mat-option *ngFor="let role of roles" [value]="role.value">{{role.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <input matInput disabled placeholder="Fecha última modificación" [value]="administrator.modified | date: 'dd/MM/yy'">
      </mat-form-field>

      <mat-form-field fxFlex="50">
        <input matInput disabled placeholder="Modificado por" [value]="administrator.author?.username">
      </mat-form-field>

    </div>

  </form>


  <app-graphql-errors [form]="formElement" [errors]="errors"></app-graphql-errors>


</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button color="primary"
          [disabled]="form.invalid || user.invalid"
          *ngIf="administrator.user" (click)="save(form.value)">Guardar</button>

  <button mat-raised-button color="primary"
          [disabled]="form.invalid"
          *ngIf="administrator.user == null" (click)="create(user.value, form.value)">Guardar</button>

  <button mat-button mat-dialog-close>Cerrar</button>

</mat-dialog-actions>
