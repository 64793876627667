<h2 mat-dialog-title>Menu</h2>


<mat-dialog-content>


  <form #form="ngForm" #formElement>


    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <mat-form-field fxFlex="50">
        <input name="date" matInput [matDatepicker]="date" placeholder="Fecha" [ngModel]="menu.date" readonly
               (click)="date.open()">
        <mat-datepicker-toggle matSuffix [for]="$any(date)"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>



      <div fxFlex="100" fxLayout="row wrap" fxLayoutGap="16px grid" *ngFor="let dish of dishes; let i = index" ngModelGroup="dishes[{{i}}]">


        <mat-form-field fxFlex="70">
          <input name="name" matInput placeholder="Nombre" required [ngModel]="dish.name">
        </mat-form-field>


        <mat-form-field fxFlex="20">
          <mat-select placeholder="Categoría" name="category" [ngModel]="dish.category" required>
            <mat-option [value]="1">Primero</mat-option>
            <mat-option [value]="2">Segundo</mat-option>
            <mat-option [value]="3">Postre</mat-option>
            <mat-option [value]="0">Entrante</mat-option>
          </mat-select>
        </mat-form-field>

        <div fxFlex="10">
          <button mat-icon-button (click)="delete(i)">
            <mat-icon svgIcon="delete"></mat-icon>
          </button>
        </div>

        <mat-form-field fxFlex="100">
          <input matInput name="notes" [ngModel]="dish.notes" placeholder="Notas">
        </mat-form-field>


        <mat-divider fxFlex="100"></mat-divider>
      </div>

    </div>


  </form>

  <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

  <button mat-raised-button color="primary" (click)="addDish()">
    <mat-icon svgIcon="plus"></mat-icon> Añadir plato
  </button>

</mat-dialog-content>


<mat-dialog-actions>




  <button mat-button mat-dialog-close>
    Cerrar
  </button>

  <button mat-raised-button color="primary"
          [disabled]="form.invalid"
          (click)="save(form.value)">Guardar
  </button>

</mat-dialog-actions>
