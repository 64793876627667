import {Injectable} from '@angular/core';
import {ActivitiesTableQueryService, ActivityInput, GetActivityQueryService, RegisterInActivityMutationService, SaveActivityMutationService} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(
    private tableService: ActivitiesTableQueryService,
    private getService: GetActivityQueryService,
    private saveService: SaveActivityMutationService,
    private registerService: RegisterInActivityMutationService,
  ){}


  adminList(pagination: Pagination) {
    return this.tableService.fetch({pagination})
  }

  get(id: string) {
    return this.getService.fetch({id})
  }

  save(activity: ActivityInput, id: string) {
    return this.saveService.mutate({id, activity})
  }

  register(id: string) {
    return this.registerService.mutate({activity: id});
  }



}
