import {Injectable} from '@angular/core';
import {
  CheckPaymentQueryService,
  PayPaymentMutationService,
  TablePaymentQueryService
} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private payService: PayPaymentMutationService,
    private checkService: CheckPaymentQueryService,
    private listService: TablePaymentQueryService
    ) {

  }


  pay(id: string) {
    return this.payService.mutate({id});
  }

  check(id: string) {
    return this.checkService.fetch({id});
  }


  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }
}
