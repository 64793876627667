import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TableModule} from "primeng/table";
import {AppRoutingModule} from "./app.routing.module";
import {GraphqlModule} from "./graphql.module";
import {RouterModule} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import {NavigationComponent} from './navigation/navigation.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {GraphqlErrorsComponent} from './utils/graphql-errors/graphql-errors.component';
import {MatMenuModule} from "@angular/material/menu";
import {AssociationsTableComponent} from './associations/associations-table/associations-table.component';
import {AssociationComponent} from './associations/association/association.component';
import {MatSelectModule} from "@angular/material/select";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule} from "@angular/material/core";
import {MatTabsModule} from "@angular/material/tabs";
import {MemberComponent} from './members/member/member.component';
import {MembersTableComponent} from './members/members-table/members-table.component';
import {PostsTableComponent} from './blog/posts-table/posts-table.component';
import {PostComponent} from './blog/post/post.component';
import {MatChipsModule} from "@angular/material/chips";
import {PostsComponent} from './blog/posts/posts.component';
import {PaginatorModule} from "primeng/paginator";
import {MatPaginatorModule} from "@angular/material/paginator";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {SafeHtmlPipe} from './utils/safe-html.pipe';
import {LoginComponent} from './users/login/login.component';
import {HomeComponent} from './associations/home/home.component';
import {MatStepperModule} from "@angular/material/stepper";
import {MatRadioModule} from "@angular/material/radio";
import {MatExpansionModule} from "@angular/material/expansion";
import {CommentComponent} from './blog/comment/comment.component';
import {CommentsComponent} from './blog/comments/comments.component';
import {PostDetailComponent} from './blog/post-detail/post-detail.component';
import {AddCommentComponent} from './blog/add-comment/add-comment.component';
import {AssociationDialogComponent} from './associations/association-dialog/association-dialog.component';
import {AssociationProfileComponent} from './associations/association-profile/association-profile.component';
import {ProfileComponent} from './members/profile/profile.component';
import {ActivityComponent} from './activities/activity/activity.component';
import {ActivitiesComponent} from './activities/activities/activities.component';
import {ActivitiesTableComponent} from './activities/activities-table/activities-table.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import es from '@angular/common/locales/es';
import {registerLocaleData} from "@angular/common";
import {MenusComponent} from './canteen/menus/menus.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule} from "@angular/material-moment-adapter";
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {CUSTOM_ADAPTER_FORMAT, CUSTOM_ADAPTER_FORMAT_DATE} from "./utils/custom-date-adapter";
import {NgxMatMomentAdapter} from "@angular-material-components/moment-adapter";
import {CheckPaymentComponent} from './payments/check/check-payment.component';
import {AdministratorComponent} from './associations/administrator/administrator.component';
import {AdministratorsTableComponent} from './associations/administrators-table/administrators-table.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {PaymentIconComponent} from './payments/payment-item/payment-icon.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {FooterComponent} from './footer/footer.component';
import {ShowParticipantsComponent} from './activities/show-participants/show-participants.component';
import {PaymentPipe} from './activities/pipes/payment.pipe';
import {MenusTableComponent} from './canteen/menus-table/menus-table.component';
import {MenuComponent} from './canteen/menu/menu.component';
import {ShowDishPipe} from './canteen/pipes/show-dish.pipe';
import {CapitalizePipe} from './utils/pipes/capitalize.pipe';
import {DishCategoryPipe} from './canteen/pipes/dish-category.pipe';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    GraphqlErrorsComponent,
    AssociationsTableComponent,
    AssociationComponent,
    MemberComponent,
    MembersTableComponent,
    PostsTableComponent,
    PostComponent,
    PostsComponent,
    SafeHtmlPipe,
    LoginComponent,
    HomeComponent,
    CommentComponent,
    CommentsComponent,
    PostDetailComponent,
    AddCommentComponent,
    AssociationDialogComponent,
    AssociationProfileComponent,
    ProfileComponent,
    ActivityComponent,
    ActivitiesComponent,
    ActivitiesTableComponent,
    MenusComponent,
    CheckPaymentComponent,
    AdministratorComponent,
    AdministratorsTableComponent,
    PaymentIconComponent,
    FooterComponent,
    ShowParticipantsComponent,
    PaymentPipe,
    MenusTableComponent,
    MenuComponent,
    ShowDishPipe,
    CapitalizePipe,
    DishCategoryPipe,
  ],
    imports: [
        HttpClientModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        TableModule,
        AppRoutingModule,
        GraphqlModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        FlexModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatOptionModule,
        MatTabsModule,
        MatChipsModule,
        PaginatorModule,
        MatPaginatorModule,
        CKEditorModule,
        MatStepperModule,
        MatRadioModule,
        MatExpansionModule,
        MatDatepickerModule,
        MomentDateModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatSlideToggleModule,
        MatTooltipModule,
    ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: "es"},
    {provide: LOCALE_ID, useValue: 'es'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_ADAPTER_FORMAT},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_ADAPTER_FORMAT_DATE}

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }

}
