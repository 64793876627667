import {Component, OnInit} from '@angular/core';
import {Post, PostDetailQuery, PostDetailQueryService} from "../../../generated/graphql";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {


  id: string;
  post: PostDetailQuery['post'] = new Post();


  constructor(
    private postService: PostDetailQueryService,
    private router: ActivatedRoute
  ) {


  }

  ngOnInit(): void {
   this.id = this.router.snapshot.paramMap.get('id');

   this.update();

  }


  update() {
    this.postService
      .fetch({id: this.id})
      .subscribe(s => this.post = s.data.post)

  }

}
