import {Pipe, PipeTransform} from '@angular/core';
import {Payment, PaymentStatus} from "../../../generated/graphql";

@Pipe({
  name: 'payment'
})
export class PaymentPipe implements PipeTransform {

  transform(payment: Payment, ...args: unknown[]): unknown {
    switch (payment.status) {
      case PaymentStatus.Paid: return 'Pagado';
      case PaymentStatus.Unpaid: return 'Sin pagar';
      case PaymentStatus.PaidManually: return 'Pagado manualmente';
    }
  }

}
