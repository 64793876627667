import {HttpLink} from 'apollo-angular/http';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {NgModule} from '@angular/core';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client'


const uri = '/graphql'; //
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));


  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('token');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
  });


  const link = ApolloLink.from([ basic, auth, createUploadLink({uri})]);


  return {
    link: link,
    cache: new InMemoryCache({
      addTypename: false
    }),
    headers: {
      'Access-Control-Allow-Credentials': 'true',
    },

    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  };
}


@NgModule({
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphqlModule {
}
