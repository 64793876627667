<mat-toolbar class="mat-elevation-z4">
  Blog
  <span fxFlex></span>

  <button mat-icon-button routerLink="administrar" *ngIf="auth.writeBlog">
    <mat-icon svgIcon="cog-outline"></mat-icon>
  </button>
</mat-toolbar>

<div id="blog" fxLayout="column" fxLayoutGap="24px">

  <mat-card fxFlex="100" *ngFor="let p of post.content" class="mat-elevation-z3">

    <mat-card-header>
      <mat-card-title>
        {{p.title}}
      </mat-card-title>
      <mat-card-subtitle>
        <div>El {{p.published | date: 'dd/MM/yy'}} a las {{p.published | date: 'HH:mm'}}</div>
      </mat-card-subtitle>
    </mat-card-header>


    <mat-card-content>
      <div class="ck-content" [innerHTML]="p.text | safeHtml"></div>



      <div class="tags">

        <mat-chip-list>
          <mat-chip *ngFor="let tag of p.tags" [routerLink]="['/blog/tag/', tag]" >{{tag}}</mat-chip>
        </mat-chip-list>

      </div>

    </mat-card-content>


    <mat-card-actions>
      <a mat-button routerLink="/blog/post/{{p.id}}">Leer más</a>
    </mat-card-actions>


  </mat-card>


</div>




<p-paginator [rows]="10" [totalRecords]="post.totalElements" (onPageChange)="onLazyEvent($event)" ></p-paginator>
