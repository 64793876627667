import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../users/auth/auth.service";

@Component({
  selector: 'app-association-profile',
  templateUrl: './association-profile.component.html',
  styleUrls: ['./association-profile.component.scss']
})
export class AssociationProfileComponent implements OnInit {

  constructor(public auth: AuthService) {


  }

  ngOnInit(): void {
  }

}
