
<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Socios</span>

  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>

</mat-toolbar>


<div class="content">
  <mat-card class="mat-elevation-z4">



    <p-table [rows]="20"
             [lazy]="true"
             [paginator]="true"
             [value]="members.content"
             [alwaysShowPaginator]="false"
             (onLazyLoad)="onLazyEvent($event)"
             [sortOrder]="1"
             sortField="name"
             [totalRecords]="members.totalElements">


      <ng-template pTemplate="header">
        <tr>
          <th>Razón social</th>
          <th>Ud. Familiar</th>
          <th>Usuario</th>
          <th>Activo</th>

          <th style="width: 64px"></th>
        </tr>
      </ng-template>



      <ng-template pTemplate="body" let-member>
        <tr>
          <td>{{member.name}}</td>
          <td>{{member.familyMembers.length}}</td>
          <td>{{member.user.username}}</td>
          <td>{{member.active ? 'Sí':'No'}}</td>
          <td>


            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(member.user.id)">
                <mat-icon svgIcon="pencil"></mat-icon>
                <span>Editar</span>
              </button>

              <button mat-menu-item (click)="delete(member.user.id)">
                <mat-icon svgIcon="delete"></mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>

          </td>
        </tr>
      </ng-template>

    </p-table>



  </mat-card>
</div>




