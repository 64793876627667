import {MatDialog} from '@angular/material/dialog';
import {Pagination} from './Pagination';
import {LazyLoadEvent} from 'primeng/api';

export abstract class TableList {

  pagination = new Pagination();

  protected constructor(
    private component: any,
    protected dialog: MatDialog) {
  }


  onLazyEvent(event: LazyLoadEvent) {
    this.pagination = new Pagination(event);
    this.updateElements();
  }


  abstract updateElements(): void;
  abstract delete(id: string): void;



  edit(id: string | null) {

    this.dialog.open(this.component, {
      width: '90%',
      maxWidth: '900px',
      position: { top: '10%' },
      data: {id}
    }).afterClosed().subscribe( _ => this.updateElements());

  }

  new() {
    this.edit(null);
  }


}
