
<mat-toolbar class="mat-elevation-z4">
  <span fxFlex>Asociaciones</span>

  <button mat-icon-button (click)="new()">
    <mat-icon svgIcon="plus"></mat-icon>
  </button>

</mat-toolbar>



<mat-card class="mat-elevation-z4 content">



  <p-table [rows]="20"
           [lazy]="true"
           [paginator]="true"
           [value]="associations.content"
           [alwaysShowPaginator]="false"
           (onLazyLoad)="onLazyEvent($event)"
           [sortOrder]="1"
           sortField="id"
           [totalRecords]="associations.totalElements">


    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th>Teléfono</th>
        <th>Presidencia</th>
        <th style="width: 64px"></th>
      </tr>
    </ng-template>



    <ng-template pTemplate="body" let-association>
      <tr>
        <td>{{association.name}}</td>
        <td>{{association.telephone }}</td>
        <td>{{association.president.name}}</td>
        <td>


          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="edit(association.id)">
              <mat-icon svgIcon="pencil"></mat-icon>
              <span>Editar</span>
            </button>

            <button mat-menu-item (click)="delete(association.id)">
              <mat-icon svgIcon="delete"></mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>

        </td>
      </tr>
    </ng-template>

  </p-table>



</mat-card>



