<h2 mat-dialog-title>Entrada del blog</h2>


<mat-dialog-content>

  <form #form="ngForm" #formElement>

    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <mat-form-field fxFlex="100">
        <input matInput maxlength="200" placeholder="Título" required name="title" [ngModel]="post.title">
      </mat-form-field>



      <div fxFlex="100">
        <ckeditor (ready)="editorReady($event)"  [editor]="editor" [config]="editorConfig"></ckeditor>

      </div>





      <mat-form-field fxFlex="100">

        <mat-chip-list #chipList aria-label="Etiquetas" multiple >
          <mat-chip
            *ngFor="let tag of tags"
            [value]="tag"
            (removed)="removeTag(tag)">
            {{tag}}
          </mat-chip>
          <input
            placeholder="Nueva etiqueta..."
            [matChipInputFor]="chipList"
            (matChipInputTokenEnd)="addTag($event)">
        </mat-chip-list>


      </mat-form-field>

    </div>

  </form>

  <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

</mat-dialog-content>

<mat-dialog-actions>

  <button mat-raised-button color="primary" (click)="save(form.value)" [disabled]="form.invalid">Guardar</button>
  <button mat-button mat-dialog-close>Cerrar</button>

</mat-dialog-actions>
