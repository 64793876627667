
<form #form="ngForm" #formElement>

  <mat-dialog-content>


    <mat-tab-group #tab>

      <mat-tab label="General">

        <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="16px grid">

          <mat-form-field fxFlex="70">
            <input placeholder="Nombre" matInput [ngModel]="association.name" name="name">
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <input placeholder="Teléfono" matInput [ngModel]="association.telephone" name="telephone">
          </mat-form-field>

          <mat-form-field fxFlex="40">
            <input placeholder="Email" matInput [ngModel]="association.email" name="email">
          </mat-form-field>


          <mat-form-field fxFlex="30">
            <input placeholder="CIF" matInput [ngModel]="association.cif" name="cif">
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <input placeholder="Número de asociación" matInput [ngModel]="association.associationNumber" name="associationNumber">
          </mat-form-field>


          <mat-form-field fxFlex="50">
            <input placeholder="Centro educativo" matInput [ngModel]="association.center" name="center">
          </mat-form-field>


          <mat-form-field fxFlex="50">
            <input placeholder="Censo" matInput [ngModel]="association.census" name="census">
          </mat-form-field>


          <mat-form-field fxFlex="100">
            <textarea rows="5" placeholder="Dirección" matInput [ngModel]="association.address" name="address"></textarea>
          </mat-form-field>


        </div>

      </mat-tab>

      <mat-tab label="Descripción">


        <div fxLayout="row wrap" fxLayoutGap="16px" class="description">
          <div fxFlex="100">
            <ckeditor (ready)="editorReady($event)"  [editor]="editor" [config]="editorConfig"></ckeditor>
          </div>

        </div>

      </mat-tab>

      <mat-tab label="Junta">


        <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="16px grid" ngModelGroup="president">

          <h3 fxFlex="100">Presidencia</h3>

          <mat-form-field fxFlex="70">
            <input placeholder="Nombre" matInput [ngModel]="association.president?.name" name="name">
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <input placeholder="DNI/NIE" matInput [ngModel]="association.president?.identification" name="identification">
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input placeholder="Email" matInput [ngModel]="association.president?.email" name="email">
          </mat-form-field>


          <mat-form-field fxFlex="50">
            <input placeholder="Teléfono" matInput [ngModel]="association.president?.telephone" name="telephone">
          </mat-form-field>
        </div>

        <mat-divider fxFlex="100"></mat-divider>

        <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="16px grid" ngModelGroup="secretary">

          <h3 fxFlex="100">Secretaría</h3>

          <mat-form-field fxFlex="70">
            <input placeholder="Nombre" matInput [ngModel]="association.secretary?.name" name="name">
          </mat-form-field>

          <mat-form-field fxFlex="30">
            <input placeholder="DNI/NIE" matInput [ngModel]="association.secretary?.identification" name="identification">
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input placeholder="Email" matInput [ngModel]="association.secretary?.email" name="email">
          </mat-form-field>


          <mat-form-field fxFlex="50">
            <input placeholder="Teléfono" matInput [ngModel]="association.secretary?.telephone" name="telephone">
          </mat-form-field>
        </div>


      </mat-tab>





      <mat-tab label="Socios">

        <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="16px grid">

          <mat-form-field fxFlex="50">
            <mat-select multiple [ngModel]="association.inactiveRoles" name="inactiveRoles" placeholder="Permisos socios inactivos">
              <mat-option *ngFor="let role of roles" [value]="role.value">{{role.label}}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field fxFlex="50">
            <mat-select multiple [ngModel]="association.activeRoles" name="activeRoles" placeholder="Permisos socios activos">
              <mat-option *ngFor="let role of roles" [value]="role.value">{{role.label}}</mat-option>
            </mat-select>
          </mat-form-field>


        </div>


      </mat-tab>

      <mat-tab label="Pago">
        <div fxLayout.lt-md="column" fxLayout="row wrap" fxLayoutGap="16px grid">

          <mat-form-field fxFlex="50">
            <input matInput [ngModel]="association.stripe" name="stripe" placeholder="Clave secreta"  type="password"/>
          </mat-form-field>

          <div fxFlex="50">
            <a  target="_blank" color="primary" href="https://www.appinvoice.com/es/s/documentacion/como-obtener-la-clave-publicable-y-la-clave-secreta-de-stripe-23#:~:text=Abre%20la%20siguiente%20p%C3%A1gina%20web,en%20%22Claves%20de%20API%22." mat-raised-button>
              Obtener clave secreta
            </a>
          </div>
        </div>

      </mat-tab>



    </mat-tab-group>


    <app-graphql-errors [errors]="errors" [form]="formElement">

    </app-graphql-errors>

  </mat-dialog-content>

  <mat-dialog-actions>

    <button mat-raised-button color="primary" (click)="save(form.value)">Guardar</button>
    <button mat-button mat-dialog-close>Cerrar</button>
  </mat-dialog-actions>

</form>
