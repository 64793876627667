import {Component} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {MatDialog} from "@angular/material/dialog";
import {AssociationComponent} from "../association/association.component";
import {AssociationsTableQuery, AssociationsTableQueryService, DeleteAssociationMutationService, PageAssociations} from "../../../generated/graphql";
import {AuthService} from "../../users/auth/auth.service";

@Component({
  selector: 'app-associations-table',
  templateUrl: './associations-table.component.html',
  styleUrls: ['./associations-table.component.scss']
})
export class AssociationsTableComponent extends TableList {


  associations: AssociationsTableQuery['associations'] = new PageAssociations();


  constructor(
    dialog: MatDialog,
    public authService: AuthService,
    private associationsTable: AssociationsTableQueryService,
    private deleteAssociation: DeleteAssociationMutationService,
  ) {
    super(AssociationComponent, dialog);

  }



  delete(id: string): void {
    this.deleteAssociation
      .mutate({id})
      .subscribe(s => this.updateElements());
  }

  updateElements(): void {
    this.associationsTable
      .fetch({pagination: this.pagination})
      .subscribe(s => this.associations = s.data.associations);

    this.authService.reloadTokens();
  }


}
