<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>

     <button mat-icon-button  (click)="drawer.toggle()"  *ngIf="isHandset$ | async">
       <mat-icon svgIcon="keyboard-backspace"></mat-icon>
     </button>   Menu

    </mat-toolbar>

    <mat-toolbar class="association-select">
      <mat-select  *ngIf="auth.profiles.length > 1"  [(value)]="auth.active" placeholder="Asociación" (selectionChange)="changeAssociation()">
        <mat-option *ngFor="let p of auth.profiles" [value]="p">
          {{ p.associationName }}
        </mat-option>
      </mat-select>
    </mat-toolbar>


    <mat-nav-list>


      <mat-list-item routerLink="inicio" *ngIf="auth.logged">
        <mat-icon svgIcon="home" mat-list-icon></mat-icon>
        <a mat-line>Inicio</a>
      </mat-list-item>


      <mat-list-item routerLink="blog" *ngIf="auth.readBlog">
        <mat-icon svgIcon="post" mat-list-icon></mat-icon>
        <a mat-line>Blog</a>
      </mat-list-item>

      <mat-list-item routerLink="actividades" *ngIf="auth.readActivities">
        <mat-icon svgIcon="ticket" mat-list-icon></mat-icon>
        <a mat-line>Actividades</a>
      </mat-list-item>


      <mat-list-item routerLink="comedor" *ngIf="auth.readCanteen">
        <mat-icon svgIcon="silverware-fork-knife" mat-list-icon></mat-icon>
        <a mat-line>Comedor</a>
      </mat-list-item>


      <mat-list-item routerLink="asociaciones" *ngIf="auth.admin">
        <mat-icon mat-list-icon svgIcon="account-group"></mat-icon>
        <a mat-line>Asociaciones</a>
      </mat-list-item>

      <mat-list-item routerLink="socios" *ngIf="auth.readMembers">
        <mat-icon svgIcon="account" mat-list-icon></mat-icon>
        <a mat-line>Socios</a>
      </mat-list-item>


      <mat-list-item routerLink="administradores" *ngIf="auth.writeAssociations">
        <mat-icon svgIcon="shield-account" mat-list-icon></mat-icon>
        <a mat-line>Administradores</a>
      </mat-list-item>


      <mat-list-item routerLink="asociacion" *ngIf="auth.writeAssociations">
        <mat-icon svgIcon="cog-outline" mat-list-icon></mat-icon>
        <a mat-line>Asociación</a>
      </mat-list-item>




    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Codapa</span>


      <span fxFlex>
      </span>

      {{ auth.active.username }}

      <button mat-icon-button (click)="logout()">
        <mat-icon svgIcon="exit-to-app"></mat-icon>
      </button>


    </mat-toolbar>


    <div id="content">
      <router-outlet></router-outlet>
    </div>



    <app-footer></app-footer>



  </mat-sidenav-content>
</mat-sidenav-container>
