<h2 mat-dialog-title>Socio</h2>


<mat-dialog-content>


  <form #user="ngForm" #userElement>

    <ng-container *ngIf="member.user == null">

      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <mat-form-field fxFlex="66">
          <input matInput ngModel placeholder="Nombre de usuario" name="username" required/>
        </mat-form-field>

        <mat-form-field fxFlex="33">

          <mat-select ngModel placeholder="Crear un usuario nuevo" name="new" required>
            <mat-option [value]="true">Crear nuevo usuario</mat-option>
            <mat-option [value]="false">Usar existente</mat-option>

          </mat-select>

        </mat-form-field>

      </div>

      <mat-divider></mat-divider>

    </ng-container>



  </form>


  <form #form="ngForm" #formElement>


    <mat-tab-group>


      <mat-tab label="General">

        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <mat-form-field fxFlex="75">
            <input matInput name="name" [ngModel]="member.name" required placeholder="Razón social"/>
          </mat-form-field>


          <mat-form-field floatLabel="always" fxFlex="25" class="slider">
            <mat-label>Activo</mat-label>
            <mat-slide-toggle [(ngModel)]="member.active" name="active"></mat-slide-toggle>
            <textarea matInput hidden></textarea>
          </mat-form-field>


        </div>

        <h3>Persona de contacto</h3>
        <div fxLayout="row wrap" fxLayoutGap="16px grid" ngModelGroup="contact">


          <mat-form-field fxFlex="25">
            <input matInput name="name" [ngModel]="member.contact?.name" required placeholder="Nombre"/>
          </mat-form-field>

          <mat-form-field fxFlex="50">
            <input matInput name="lastName" [ngModel]="member.contact?.lastName" required placeholder="Apellidos"/>
          </mat-form-field>


          <mat-form-field fxFlex="25">
            <input matInput name="dni" [ngModel]="member.contact?.dni" required placeholder="DNI"/>
          </mat-form-field>

          <mat-form-field fxFlex="25">
            <input matInput name="phone" [ngModel]="member.contact?.phone" required placeholder="Teléfono">
          </mat-form-field>
          <mat-form-field fxFlex="75">
            <input matInput name="email" [ngModel]="member.contact?.email" required placeholder="Email"/>
          </mat-form-field>

        </div>

      </mat-tab>

      <mat-tab label="Unidad familiar">

          <div *ngFor="let family of familyMembers; let i = index" fxLayout="row wrap" fxLayoutGap="16px grid" ngModelGroup="familyMembers[{{i}}]">
              <mat-form-field fxFlex="25">
                <input matInput [ngModel]="family.name" placeholder="Nombre" name="name">
              </mat-form-field>

              <mat-form-field fxFlex="40">
                <input matInput [ngModel]="family.lastName" placeholder="Apellidos" name="lastName">
              </mat-form-field>


              <mat-form-field fxFlex="25">
                <mat-select [ngModel]="family.course" placeholder="Curso" name="course">
                  <mat-option value="---">---</mat-option>
                  <mat-optgroup *ngFor="let g of courses" [label]="g.group">
                    <mat-option *ngFor="let c of g.courses" [value]="c">{{c}}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>

            <div fxFlex="10">
              <button mat-icon-button (click)="deleteFamilyMember(i)">
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
            </div>

            <mat-divider fxFlex="100"></mat-divider>
          </div>


        <button mat-raised-button color="primary" (click)="addFamilyMember()">Añadir miembro</button>


      </mat-tab>


      <mat-tab label="Recibos">

        <mat-list>
          <mat-list-item *ngFor="let p of payments.content">
            <app-payment-icon mat-list-icon [payment]="p" ></app-payment-icon>
            <div mat-line>{{p.name}}</div>
            <div mat-line>{{p.date | date: 'dd/MM/yyyy'}}</div>
          </mat-list-item>


        </mat-list>


        <button mat-raised-button disabled>Pagar cuota</button>

      </mat-tab>


    </mat-tab-group>



  </form>

  <app-graphql-errors [errors]="errors" [form]="formElement"></app-graphql-errors>

</mat-dialog-content>


<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    Cerrar
  </button>

  <button mat-raised-button color="primary"
          [disabled]="form.invalid || user.invalid"
          *ngIf="member.user" (click)="save(form.value)">Guardar</button>

  <button mat-raised-button color="primary"
          [disabled]="form.invalid"
          *ngIf="member.user == null" (click)="create(user.value, form.value)">Guardar</button>

</mat-dialog-actions>
