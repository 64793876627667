import {Pagination} from './Pagination';

export abstract class PaginationList{

  pagination = new Pagination();

  protected constructor() {

  }


  onLazyEvent(event: any) {
    this.pagination = new Pagination(event);
    this.updateElements();
  }


  abstract updateElements(): void;



}
