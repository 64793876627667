import {Component} from '@angular/core';
import {AdministratorsService} from "../services/administrators.service";
import {TableList} from "../../utils/TableList";
import {AdministratorComponent} from "../administrator/administrator.component";
import {MatDialog} from "@angular/material/dialog";
import {GetAdministratorsQuery, PageAdministrators} from "../../../generated/graphql";

@Component({
  selector: 'app-administrators-table',
  templateUrl: './administrators-table.component.html',
  styleUrls: ['./administrators-table.component.scss']
})
export class AdministratorsTableComponent extends TableList {

  administrators: GetAdministratorsQuery['administrators'] = new PageAdministrators();

  constructor(private administratorService: AdministratorsService, dialog: MatDialog) {
    super(AdministratorComponent, dialog);
  }

  ngOnInit(): void {
  }

  delete(id: string): void {
    this.administratorService.delete(id).subscribe( s => this.updateElements());
  }

  updateElements(): void {
    this.administratorService.list(this.pagination).subscribe(s => this.administrators = s.data.administrators);
  }


}

