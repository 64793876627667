import {Component} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AuthService} from "../users/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(

    public auth: AuthService,
    private breakpointObserver: BreakpointObserver,
    private router: Router


  ) {}

  changeAssociation() {
    this.router.navigate(['/inicio']);
  }

  logout() {
    this.auth.clearTokens();
    this.router.navigate(['/']);
  }
}
