<mat-card>
  <mat-card-title>Identificación</mat-card-title>


  <form #form="ngForm">

    <mat-card-content>

      <div fxLayout="column" fxLayoutGap="16px grid">

        <mat-form-field fxFlex="100">
          <input matInput name="username" placeholder="Nombre de usuario" ngModel/>
        </mat-form-field>

        <mat-form-field fxFlex="100">
          <input matInput name="password" type="password" placeholder="Contraseña" ngModel>
        </mat-form-field>


        <mat-error *ngIf="error" fxFlex="100">
          Usuario o contraseña incorrectos.
        </mat-error>


      </div>


    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-raised-button color="primary" (click)="send(form.value)">Acceder</button>
    </mat-card-actions>

  </form>


</mat-card>
