import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  FamilyMember,
  GetMemberQuery,
  Member,
  MemberInput,
  PagePayments,
  TablePaymentQuery
} from "../../../generated/graphql";
import {GraphQLError} from "graphql";
import {convertArray} from "../../utils/forms-utils";
import {MembersService} from "../services/members.service";
import {PaymentsService} from "../../payments/services/payments.service";
import {Filter, Pagination} from "../../utils/Pagination";
import {LazyLoadEvent} from "primeng/api";

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {


  errors: readonly GraphQLError[];
  pagination = new Pagination();
  payments: TablePaymentQuery['payments'] = new PagePayments();

  member: GetMemberQuery['member'] = new Member();

  familyMembers = new Array<FamilyMember>();


  constructor(
    private membersService: MembersService,
    private dialog: MatDialogRef<MemberComponent>,
    private paymentService: PaymentsService,
    @Inject(MAT_DIALOG_DATA) data: any) {

    if (data.id) {
      membersService.get(data.id)
        .subscribe(s => {
          this.member = s.data.member;
          this.familyMembers.push(...s.data.member.familyMembers);
        });

    }

    this.member.id = data.id;



  }




  ngOnInit() {
    this.updatePayments(null);
  }


  updatePayments(event: LazyLoadEvent) {
    this.pagination = new Pagination(event);
    this.pagination.addDefaultFilter(new Filter('user.id', this.member.id, 'equals', 'and'))
    this.paymentService.list(this.pagination).subscribe( s => this.payments = s.data.payments);
  }



  save(member: MemberInput) {

    convertArray(member);


    this.membersService.save(member, this.member.user.id)
      .subscribe(s => {
        this.errors = s.errors;
        if (s.data) this.dialog.close();
      })

  }


  addFamilyMember() {
    this.familyMembers.push(new FamilyMember());
  }


  create(values: any, member: MemberInput) {

    this.membersService
      .addUser(values.username, member.contact.email, values.new)
      .subscribe(s => {
        this.errors = s.errors;

        if (s.data.addUser) {
          this.member.user = s.data.addUser;
          this.save(member);
        }
      });
  }


  deleteFamilyMember(index: number) {
    this.familyMembers.splice(index, 1);
  }

  courses = [
    {
      group: 'Primaria',
      courses: ['Infantil', 'Primero', 'Segundo', 'Tercero', 'Cuarto', 'Quinto', 'Sexto']
    },
    {
      group: 'Secundaria',
      courses: ['1º ESO', '2º ESO', '3º ESO', '4º ESO', '1º Bachiller', '2º Bachiller']
    }
  ];
}
