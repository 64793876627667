import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Association,
  AssociationInput,
  GetAssociationQuery,
  GetAssociationQueryService,
  SaveAssociationMutationService
} from "../../../generated/graphql";
import {GraphQLError} from "graphql";
import {convertArray} from "../../utils/forms-utils";
import * as Editor from "../../utils/editor/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";
import {AuthService} from "../../users/auth/auth.service";

@Component({
  selector: 'app-association',
  templateUrl: './association.component.html',
  styleUrls: ['./association.component.scss']
})
export class AssociationComponent implements OnInit {



  @Input()
  associationId: string;

  @Output()
  saved = new EventEmitter();

  errors: readonly GraphQLError[];
  errorsAdmin: readonly GraphQLError[];
  association: GetAssociationQuery['association'] = new Association();



  editor: any = Editor;

  ckEditor: CKEditor5.Editor;

  editorConfig = {
    toolbar: {
      items: [
        'heading',
        'bold',
        'italic',
        'link',
        'alignment',
        'fontColor',
        'fontSize',
        '|',
        'horizontalLine',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
      ]
    },
    language: 'es',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
  };



  constructor(
    private getAssociationService: GetAssociationQueryService,
    private saveAssociation: SaveAssociationMutationService,
  ) {


  }

  ngOnInit(): void {

    this.association.id = this.associationId;

    if (this.association.id)
      this.getAssociationService
        .fetch({id: this.associationId})
        .subscribe(s => {
          this.association = s.data.association;
          this.ckEditor.setData(this.association.description);
        });


  }


  save(association: AssociationInput) {

    convertArray(association);

    association.description = this.ckEditor.getData();


    this.saveAssociation
      .mutate({id: this.association.id, association})
      .subscribe(s => {
        this.errors = s.errors;

        if (s.data)
          this.saved.emit();

      });

  }




  editorReady($event: CKEditor5.Editor) {
    this.ckEditor = $event;
  }


  roles = AuthService.BASIC_ROLES;
}
