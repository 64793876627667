import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AssociationsTableComponent} from "./associations/associations-table/associations-table.component";
import {MembersTableComponent} from "./members/members-table/members-table.component";
import {PostsTableComponent} from "./blog/posts-table/posts-table.component";
import {PostsComponent} from "./blog/posts/posts.component";
import {LoginComponent} from "./users/login/login.component";
import {UserGuard} from "./guards/user.guard";
import {HomeComponent} from "./associations/home/home.component";
import {PostDetailComponent} from "./blog/post-detail/post-detail.component";
import {AssociationProfileComponent} from "./associations/association-profile/association-profile.component";
import {ActivitiesTableComponent} from "./activities/activities-table/activities-table.component";
import {ActivitiesComponent} from "./activities/activities/activities.component";
import {MenusComponent} from "./canteen/menus/menus.component";
import {CheckPaymentComponent} from "./payments/check/check-payment.component";
import {GuestGuard} from "./guards/guest.guard";
import {AdministratorsTableComponent} from "./associations/administrators-table/administrators-table.component";
import {MenusTableComponent} from "./canteen/menus-table/menus-table.component";


const routes: Routes = [

  {
    path: '', component: LoginComponent, canActivate: [GuestGuard]
  },

  {
    path: 'inicio', component: HomeComponent, canActivate: [UserGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'asociaciones', component: AssociationsTableComponent, canActivate: [UserGuard]
  },


  {
    path: 'administradores', component: AdministratorsTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'socios', component: MembersTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'anuncios', component: PostsTableComponent, canActivate: [UserGuard]
  },


  {
    path: 'blog', component: PostsComponent, canActivate: [UserGuard],
  },

  {
    path: 'blog/administrar', component: PostsTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'blog/tag/:tag', component: PostsComponent, canActivate: [UserGuard]
  },
  {
    path: 'blog/post/:id', component: PostDetailComponent, canActivate: [UserGuard]
  },

  {
    path: 'asociacion', component: AssociationProfileComponent, canActivate: [UserGuard]
  },

  {
    path: 'actividades/administrar', component: ActivitiesTableComponent, canActivate: [UserGuard]
  },

  {

    path: 'actividades', component: ActivitiesComponent, canActivate: [UserGuard]


  },

  {
    path: 'comedor', component: MenusComponent, canActivate: [UserGuard]
  },


  {
    path: 'comedor/administrar', component: MenusTableComponent, canActivate: [UserGuard]
  },

  {
    path: 'pagos/comprobar/:id', component: CheckPaymentComponent, canActivate: [UserGuard]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
