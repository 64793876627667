
<h2 mat-dialog-content>Inscritos en la actividad</h2>



<mat-dialog-content>

  <p-table [rows]="20"
           [lazy]="true"
           [paginator]="true"
           [value]="participants"
           [alwaysShowPaginator]="false"
           [sortOrder]="1"
           sortField="id"
           [totalRecords]="participants.length">


    <ng-template pTemplate="header">
      <tr>
        <th>Nombre</th>
        <th>Usuario</th>
        <th>Estado</th>
        <th style="width: 64px"></th>
      </tr>
    </ng-template>


    <ng-template pTemplate="body" let-participant>
      <tr>
        <td>{{participant.user.name}}</td>
        <td>{{participant.user.username}}</td>
        <td>{{participant.payment | payment}}</td>
        <td>


          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">

          </mat-menu>

        </td>
      </tr>
    </ng-template>

  </p-table>



</mat-dialog-content>


<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
