import {Injectable} from '@angular/core';
import {Profile} from "./profile";
import jwtDecode from "jwt-decode";
import {ReloadTokenQueryService} from "../../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _active = new Profile();
  profiles = new Array<Profile>();




  get active() {
    return this._active;
  }

  set active(value) {
    this._active = value;
    let actual = this.profiles.findIndex(p => this.active.association == p.association);

    const tokens = JSON.parse(localStorage.getItem('tokens') || '[]') as string[];

    localStorage.setItem('token', tokens[actual]);
  }

  constructor(
    private reloadTokenService: ReloadTokenQueryService
  ) {
    this.loadTokens();
    this.reloadTokens();
  }


  newTokens(tokens: string[]) {
    this.profiles = tokens.map(t => jwtDecode<Profile>(t, {header: false}));

    if (this.profiles.length == 0)
      return;

    let actual = this.profiles.findIndex(p => this.active.association == p.association);

    if (actual == -1)
      actual = 0;


    this.active = this.profiles[actual];

    localStorage.setItem('token', tokens[actual]);
    localStorage.setItem('tokens', JSON.stringify(tokens));
  }


  loadTokens() {
    const token = localStorage.getItem('token');
    const tokens = JSON.parse(localStorage.getItem('tokens') || '[]') as string[];

    this.profiles = tokens.map(t => jwtDecode<Profile>(t, {header: false}));


    const active = tokens.indexOf(token);

    if (active == -1)
      return;


    this.active = this.profiles[active];


  }

  clearTokens() {
    this.profiles = [];
    this.active = new Profile();
    localStorage.setItem('token', '');
    localStorage.setItem('tokens', '[]');

  }


  reloadTokens() {
    this.reloadTokenService.fetch()
      .subscribe(s => {

        if (s.data)
          this.newTokens(s.data.reloadToken);
        else
          this.clearTokens();

      })
  }

  get member() {
    return this.hasPermission(AuthService.MEMBER);
  }

  get admin() {
    return this.hasPermission(AuthService.ADMIN);
  }

  get readMembers() {
    return this.hasPermission(AuthService.READ_MEMBERS);
  }

  get writeMembers() {
    return this.hasPermission(AuthService.WRITE_MEMBERS);
  }

  get readBlog() {
    return this.hasPermission(AuthService.READ_BLOG);
  }

  get writeBlog() {
    return this.hasPermission(AuthService.WRITE_BLOG);
  }

  get commentBlog() {
    return this.hasPermission(AuthService.COMMENT_BLOG);
  }

  get readActivities() {
    return this.hasPermission(AuthService.READ_ACTIVITIES);
  }

  get writeActivities() {
    return this.hasPermission(AuthService.WRITE_ACTIVITIES);
  }

  get singUpActivities() {
    return this.hasPermission(AuthService.SING_UP_ACTIVITIES);
  }

  get readCanteen() {
    return this.hasPermission(AuthService.READ_CANTEEN);
  }

  get writeCanteen() {
    return this.hasPermission(AuthService.WRITE_CANTEEN);
  }

  get singUpCanteen() {
    return this.hasPermission(AuthService.SING_UP_CANTEEN);
  }

  get readUsers() {
    return this.hasPermission(AuthService.READ_USERS);
  }


  get writeUsers() {
    return this.hasPermission(AuthService.WRITE_USERS);
  }

  get readAssociations() {
    return this.hasPermission(AuthService.READ_ASSOCIATIONS);
  }


  get writeAssociations() {
    return this.hasPermission(AuthService.WRITE_ASSOCIATIONS);
  }


  get logged() {
    return this.active.id != ''
  }


  private hasPermission(permission: string) {
    return this.active.roles.indexOf(permission) > -1;
  }

  static MEMBER = "ROLE_MEMBER";
  static ADMIN = "ROLE_ADMIN";
  static READ_MEMBERS = "ROLE_READ_MEMBERS";
  static WRITE_MEMBERS = "ROLE_WRITE_MEMBERS";
  static READ_BLOG = "ROLE_READ_BLOG";
  static WRITE_BLOG = "ROLE_WRITE_BLOG";
  static COMMENT_BLOG = "ROLE_COMMENT_BLOG";
  static READ_ACTIVITIES = "ROLE_READ_ACTIVITIES";
  static WRITE_ACTIVITIES = "ROLE_WRITE_ACTIVITIES";
  static SING_UP_ACTIVITIES = "ROLE_SING_UP_ACTIVITIES";
  static READ_CANTEEN = "ROLE_READ_CANTEEN";
  static WRITE_CANTEEN = "ROLE_WRITE_CANTEEN";

  static SING_UP_CANTEEN = "ROLE_SING_UP_CANTEEN";
  static READ_USERS = "ROLE_READ_USERS";


  static WRITE_USERS = "ROLE_WRITE_USERS";
  static READ_ASSOCIATIONS = "ROLE_READ_ASSOCIATIONS";

  static WRITE_ASSOCIATIONS = "ROLE_WRITE_ASSOCIATIONS";


  static ROLES = [
    {value: AuthService.WRITE_ASSOCIATIONS, label: 'Editar asociación'},
    {value: AuthService.READ_MEMBERS, label: 'Ver socios'},
    {value: AuthService.WRITE_MEMBERS, label: 'Editar socios'},
    {value: AuthService.READ_BLOG, label: 'Ver blog'},
    {value: AuthService.WRITE_BLOG, label: 'Editar blog'},
    {value: AuthService.COMMENT_BLOG, label: 'Comentar'},
    {value: AuthService.READ_ACTIVITIES, label: 'Ver actividades'},
    {value: AuthService.WRITE_ACTIVITIES, label: 'Editar actividades'},
    {value: AuthService.SING_UP_ACTIVITIES, label: 'Apuntarse a actividades'},
    {value: AuthService.READ_CANTEEN, label: 'Ver comedor'},
    {value: AuthService.WRITE_CANTEEN, label: 'Editar comedor'},
    {value: AuthService.SING_UP_CANTEEN, label: 'Apuntarse al comedor'},
  ];


  static BASIC_ROLES = [
    {value: AuthService.READ_BLOG, label: 'Ver blog'},
    {value: AuthService.COMMENT_BLOG, label: 'Comentar'},
    {value: AuthService.READ_ACTIVITIES, label: 'Ver actividades'},
    {value: AuthService.SING_UP_ACTIVITIES, label: 'Apuntarse a actividades'},
    {value: AuthService.READ_CANTEEN, label: 'Ver comedor'},
    {value: AuthService.SING_UP_CANTEEN, label: 'Apuntarse al comedor'},
  ];



}




