

<div class="content">

  <mat-card>

    <mat-card-content>
      <app-association [associationId]="auth.active.association"></app-association>
    </mat-card-content>

  </mat-card>

</div>


