import {Component, Inject} from '@angular/core';
import {GetPostQuery, GetPostQueryService, Post, PostInput, SavePostMutationService} from "../../../generated/graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GraphQLError} from "graphql";
import {MatChipInputEvent} from "@angular/material/chips";
import {AuthService} from "../../users/auth/auth.service";

import * as Editor from 'src/app/utils/editor/ckeditor'
import {CKEditor5} from "@ckeditor/ckeditor5-angular";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent {


  post: GetPostQuery['post'] = new Post();
  errors: readonly GraphQLError[] = [];
  tags = new Array<string>();


  editor: any = Editor;

  ckEditor: CKEditor5.Editor;

  editorConfig = {
    toolbar: {
      items: [
        'heading',
        'bold',
        'italic',
        'link',
        'alignment',
        'fontColor',
        'fontSize',
        '|',
        'horizontalLine',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
      ]
    },
    language: 'es',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
  };


  constructor(
    private dialog: MatDialogRef<PostComponent>,
    private savePostService: SavePostMutationService,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any,
    getPostService: GetPostQueryService
  ) {

    if (data.id) {
      getPostService
        .fetch({id: data.id})
        .subscribe(s => {
          this.post = s.data.post;
          this.tags.push(...s.data.post.tags);

          if (this.ckEditor)
            this.ckEditor.setData(this.post.text);

        });
    }


  }

  save(post: PostInput) {

    post.tags = this.tags;
    post.text = this.ckEditor.getData();

    this.savePostService
      .mutate({post, id: this.post.id})
      .subscribe(s => {
        this.errors = s.errors;
        if (s.data)
          this.dialog.close();
      });

  }


  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index > -1)
      this.tags.splice(index, 1);
  }

  addTag(event: MatChipInputEvent) {

    const tag = event.value;
    this.removeTag(tag);
    this.tags.push(tag);

    event.chipInput.clear();
  }


  editorReady($event: CKEditor5.Editor) {
    this.ckEditor = $event;
    this.ckEditor.setData(this.post.text);
  }
}
