import {Injectable} from '@angular/core';
import {AddUserMutationService, GetMemberQueryService, MemberInput, SaveMemberMutationService} from "../../../generated/graphql";

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  constructor(
    private saveService: SaveMemberMutationService,
    private getService: GetMemberQueryService,
    private addService: AddUserMutationService

  ) {
  }


  get(id: string) {
    return this.getService.fetch({id});
  }

  save(member: MemberInput, id: string) {
    return this.saveService.mutate({member, id});
  }

  create(member: MemberInput) {
    return this.saveService.mutate({member});
  }


  addUser(username: string, email: string, isNew: boolean) {
    return this.addService.mutate({username, email, new: isNew});
  }


}
