import {Component} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {MatDialog} from "@angular/material/dialog";
import {ActivityComponent} from "../activity/activity.component";
import {ActivitiesTableQuery, ActivitiesTableQueryService, PageActivities} from "../../../generated/graphql";

@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.scss']
})
export class ActivitiesTableComponent extends TableList {


  activities: ActivitiesTableQuery['activities'] = new PageActivities();


  constructor(dialog: MatDialog,
              private activitiesTable: ActivitiesTableQueryService
  ) {
    super(ActivityComponent, dialog);
  }

  ngOnInit(): void {
  }

  delete(id: string): void {
  }

  updateElements(): void {
    this.activitiesTable
      .fetch({pagination: this.pagination})
      .subscribe( s => this.activities = s.data.activities);
  }

}
