import {Component, Inject, OnInit} from '@angular/core';
import {AdministratorsService} from "../services/administrators.service";
import {Administrator, AdministratorInput, GetAdministratorQuery} from "../../../generated/graphql";
import {GraphQLError} from "graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../users/auth/auth.service";

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit {

  errors: readonly GraphQLError[];
  administrator: GetAdministratorQuery['administrator'] = new Administrator();

  constructor(
    private dialog: MatDialogRef<AdministratorComponent>,
    private administratorsService: AdministratorsService,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any) {


    if (data.id)
      this.administratorsService
        .get(data.id)
        .subscribe( s => this.administrator = s.data.administrator);


  }

  ngOnInit(): void {
  }

  save(administrator: AdministratorInput) {

    this.administratorsService
      .save(administrator, this.administrator.user.id)
      .subscribe( s => {
        this.errors = s.errors;

        if (s.data)
          this.dialog.close();

      });


  }

  create(values: any, administrator: AdministratorInput) {

    this.administratorsService
      .addUser(values.username, administrator.email, values.new)
      .subscribe(s => {
        this.errors = s.errors;

        if (s.data.addUser) {
          this.administrator.user = s.data.addUser;
          this.save(administrator);
        }
      });
  }

  roles = AuthService.ROLES;

}
