import {Component, OnInit} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {MatDialog} from "@angular/material/dialog";
import {MenuComponent} from "../menu/menu.component";
import {PageMenu, TableMenusQuery} from "../../../generated/graphql";
import {CanteenService} from "../services/canteen.service";

@Component({
  selector: 'app-menus-table',
  templateUrl: './menus-table.component.html',
  styleUrls: ['./menus-table.component.scss']
})
export class MenusTableComponent extends TableList implements OnInit {



  menus: TableMenusQuery['menus'] = new PageMenu();


  constructor(
    dialog: MatDialog,
    private canteenService: CanteenService

  ) {

    super(MenuComponent, dialog);


  }

  ngOnInit(): void {
  }

  delete(id: string): void {
  }

  updateElements(): void {
    this.canteenService.list(this.pagination).subscribe( s => this.menus = s.data.menus);
  }

}
