import {Injectable} from '@angular/core';
import {AddUserMutationService, AdministratorInput, DeleteAdministratorMutationService, GetAdministratorQueryService, GetAdministratorsQueryService, SaveAdministratorMutationService} from "../../../generated/graphql";
import {Pagination} from "../../utils/Pagination";

@Injectable({
  providedIn: 'root'
})
export class AdministratorsService {

  constructor(
    private listService: GetAdministratorsQueryService,
    private getService: GetAdministratorQueryService,
    private addService: AddUserMutationService,
    private saveService: SaveAdministratorMutationService,
    private deleteService: DeleteAdministratorMutationService,
  ) {


  }


  list(pagination: Pagination) {
    return this.listService.fetch({pagination});
  }


  get(id: string) {
    return this.getService.fetch({id});
  }


  addUser(username: string, email: string, isNew: boolean) {
    return this.addService.mutate({username, email, new: isNew});
  }


  save(administrator: AdministratorInput, user: string) {
    return this.saveService.mutate({administrator, user});
  }

  delete(user: string) {
    return this.deleteService.mutate({user})
  }
}
