import {Component, Inject, OnInit} from '@angular/core';
import {GraphQLError} from "graphql";
import * as Editor from "../../utils/editor/ckeditor";
import {CKEditor5} from "@ckeditor/ckeditor5-angular";
import {EditorConfig} from "../../utils/editor/EditorConfig";
import {Activity, ActivityInput, GetActivityQuery, GetActivityQueryService, SaveActivityMutationService} from "../../../generated/graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {


  activity: GetActivityQuery['activity'] = new Activity();



  errors: readonly GraphQLError[] = [];
  tags = new Array<string>();


  editor: any = Editor;

  ckEditor: CKEditor5.Editor;

  editorConfig = EditorConfig.defaultConfig;


  constructor(
    private getActivityService: GetActivityQueryService,
    private saveActivityService: SaveActivityMutationService,
    private dialog: MatDialogRef<ActivityComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,

  ) {

    this.activity.id = data.id;


  }

  ngOnInit(): void {

    if (this.activity.id == null)
      return;

    this.getActivityService
      .fetch({id: this.activity.id })
      .subscribe( s => {
        this.activity = s.data.activity;
        this.ckEditor.setData(this.activity.description);
      });

  }


  editorReady($event: CKEditor5.Editor) {
    this.ckEditor = $event;
    if (this.activity.description)
      this.ckEditor.setData(this.activity.description);
  }


  save(activity: ActivityInput) {

    activity.description = this.ckEditor.getData();

    this.saveActivityService
      .mutate({id: this.activity.id, activity})
      .subscribe( s => {
        this.errors = s.errors;

        if (!this.errors) {
          this.dialog.close();
        }

      });

  }

}
