import {Component, OnInit} from '@angular/core';
import {PagePost, PostsQuery, PostsQueryService} from "../../../generated/graphql";
import {MatDialog} from "@angular/material/dialog";
import {PaginationList} from "../../utils/PaginationList";
import {AuthService} from "../../users/auth/auth.service";

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent extends PaginationList implements OnInit {


  post: PostsQuery['posts'] = new PagePost();


  constructor(
    dialog: MatDialog,
    public auth: AuthService,
    private postsService: PostsQueryService,
  ) {

    super();

  }

  ngOnInit(): void {
    this.updateElements();
  }


  updateElements(): void {
    this.postsService
      .fetch({pagination: this.pagination})
      .subscribe(s => this.post = s.data.posts);
  }


}
