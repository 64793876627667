import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../users/auth/auth.service";
import {TableList} from "../../utils/TableList";
import {CanteenService} from "../services/canteen.service";
import {PageMenu, TableMenusQuery} from "../../../generated/graphql";

import {DateTime} from 'luxon';


@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent extends TableList implements OnInit  {


  menus: TableMenusQuery['menus'] = new PageMenu();

  end = DateTime.now().endOf('week').toJSDate();
  start = DateTime.now().startOf('week').toJSDate();


  constructor(
    public auth: AuthService,
    private canteenService: CanteenService

  ) {
    super(null, null);
  }


  ngOnInit(): void {
    this.updateElements();
  }

  delete(id: string): void {
  }

  updateElements(): void {

    this.pagination.filters = [
      {value: this.start, field: 'date', matchMode: 'dateAfter', operator: 'and'},
      {value: this.end, field: 'date', matchMode: 'dateBefore', operator: 'and'},
    ];

    this.canteenService.list(this.pagination).subscribe( s => this.menus = s.data.menus);

  }


  setWeek(direction: number) {
    this.end = DateTime.fromJSDate(this.end).plus({week: direction}).toJSDate();
    this.start = DateTime.fromJSDate(this.start).plus({week: direction}).toJSDate();
    this.updateElements();
  }


}
