import {Component, Input, OnInit} from '@angular/core';
import {PostDetailQuery} from "../../../generated/graphql";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {


  @Input()
  comments: PostDetailQuery['post']['comments'];


  constructor() { }

  ngOnInit(): void {


  }

}
