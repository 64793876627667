import {Component, Input, OnInit} from '@angular/core';
import {Payment, PaymentStatus} from "../../../generated/graphql";

@Component({
  selector: 'app-payment-icon',
  templateUrl: './payment-icon.component.html',
  styleUrls: ['./payment-icon.component.scss']
})
export class PaymentIconComponent implements OnInit {


  @Input()
  payment: Payment


  constructor() { }

  ngOnInit(): void {


  }


  get icon() {
    switch (this.payment.status) {
      case PaymentStatus.Paid: return 'credit-card-check';
      case PaymentStatus.Unpaid: return 'credit-card-clock';
      case PaymentStatus.PaidManually: return 'cash-check';
    }
  }


  get tooltip() {
    switch (this.payment.status) {
      case PaymentStatus.Paid: return 'Pagado';
      case PaymentStatus.Unpaid: return 'Sin pagar';
      case PaymentStatus.PaidManually: return 'Pagado manualmente';
    }
  }


  get status() {
    switch (this.payment.status) {
      case PaymentStatus.Paid: return 'success-text';
      case PaymentStatus.Unpaid: return 'warning-text';
      case PaymentStatus.PaidManually: return 'success-text';
    }
  }


}
