import {Component} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {MatDialog} from "@angular/material/dialog";
import {MemberComponent} from "../member/member.component";
import {DeleteMemberMutationService, MembersTableQuery, MembersTableQueryService, PageMembers} from "../../../generated/graphql";

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss']
})
export class MembersTableComponent extends TableList {


  members: MembersTableQuery['members'] = new PageMembers();

  constructor(
    private membersService: MembersTableQueryService,
    private deleteMembers: DeleteMemberMutationService,
    dialog: MatDialog) {
    super(MemberComponent, dialog);
  }


  delete(id: string): void {
    this.deleteMembers
      .mutate({id})
      .subscribe(_ => this.updateElements());
  }

  updateElements(): void {
    this.membersService
      .fetch({pagination: this.pagination})
      .subscribe(s => this.members = s.data.members);
  }

}
