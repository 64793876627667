<form #form="ngForm">

  <mat-card>

    <mat-card-subtitle>Comentar</mat-card-subtitle>

    <div fxLayout="row wrap" fxLayoutGap="16px grid">

      <mat-form-field fxFlex="100">
        <textarea matInput ngModel placeholder="Texto del comentario" rows="3" name="text"></textarea>
      </mat-form-field>


      <div fxFlex></div>

      <div>
        <button mat-raised-button color="primary" (click)="sendComment(form)">
          Enviar
        </button>

      </div>

    </div>


  </mat-card>
</form>
