import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  DateTime: any;
};



export class Activity {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  maxParticipants?: Maybe<Scalars['Int']>;
  participants?: Maybe<Array<Maybe<Participant>>>;
  registered: Scalars['Int'];
  payment?: Maybe<Payment>;
};

export class ActivityInput {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  maxParticipants?: Maybe<Scalars['Int']>;
};

export class Administrator {
  roles: Array<Scalars['String']>;
  user: User;
  dni: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  author: User;
  charge: Scalars['String'];
  modified: Scalars['DateTime'];
};

export class AdministratorInput {
  roles?: Maybe<Array<Scalars['String']>>;
  dni: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  charge: Scalars['String'];
};

export class Association {
  id: Scalars['String'];
  cif: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  center?: Maybe<Scalars['String']>;
  census?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  associationNumber?: Maybe<Scalars['String']>;
  president?: Maybe<Directive>;
  secretary?: Maybe<Directive>;
  activeRoles: Array<Scalars['String']>;
  inactiveRoles: Array<Maybe<Scalars['String']>>;
  payments: Scalars['Boolean'];
  stripe: Scalars['String'];
};

export class AssociationInput {
  cif: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  center?: Maybe<Scalars['String']>;
  census?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  associationNumber?: Maybe<Scalars['String']>;
  president?: Maybe<DirectiveInput>;
  secretary?: Maybe<DirectiveInput>;
  activeRoles: Array<Scalars['String']>;
  inactiveRoles: Array<Maybe<Scalars['String']>>;
};

export class Comment {
  id: Scalars['String'];
  text: Scalars['String'];
  user: User;
  date?: Maybe<Scalars['DateTime']>;
};

export class CommentInput {
  text: Scalars['String'];
};

export class Contact {
  name: Scalars['String'];
  lastName: Scalars['String'];
  dni: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export class ContactInput {
  name: Scalars['String'];
  lastName: Scalars['String'];
  dni: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};


export class Directive {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  identification?: Maybe<Scalars['String']>;
};

export class DirectiveInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  identification?: Maybe<Scalars['String']>;
};

export class Dish {
  name: Scalars['String'];
  notes: Scalars['String'];
  category: Scalars['Int'];
};

export class DishInput {
  name: Scalars['String'];
  notes: Scalars['String'];
  category: Scalars['Int'];
};

export class FamilyMember {
  name: Scalars['String'];
  lastName: Scalars['String'];
  course: Scalars['String'];
};

export class FamilyMemberInput {
  name: Scalars['String'];
  lastName: Scalars['String'];
  course: Scalars['String'];
};

export class Filter {
  field: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  matchMode?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['String']>;
};

export class Member {
  id: Scalars['String'];
  name: Scalars['String'];
  active: Scalars['Boolean'];
  roles: Array<Maybe<Scalars['String']>>;
  contact: Contact;
  familyMembers: Array<FamilyMember>;
  association: Association;
  user: User;
};

export class MemberInput {
  name: Scalars['String'];
  active: Scalars['Boolean'];
  contact: ContactInput;
  familyMembers?: Maybe<Array<FamilyMemberInput>>;
};

export class Menu {
  id: Scalars['String'];
  date: Scalars['DateTime'];
  dishes?: Maybe<Array<Maybe<Dish>>>;
};

export class MenuInput {
  date: Scalars['DateTime'];
  dishes?: Maybe<Array<Maybe<DishInput>>>;
};

export class Mutation {
  saveActivity?: Maybe<Activity>;
  deleteActivity?: Maybe<Activity>;
  registerInActivity: Payment;
  deleteAssociation: Association;
  saveAssociation: Association;
  saveAdministrator: Administrator;
  deleteAdministrator: Administrator;
  savePost: Post;
  saveComment: Comment;
  deletePost: Post;
  deleteMember: Member;
  saveMember: Member;
  saveMenu: Menu;
  deleteMenu: Menu;
  payPayment?: Maybe<Scalars['String']>;
  saveUser: User;
  addUser: User;
};


export class MutationSaveActivityArgs {
  activity: ActivityInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationDeleteActivityArgs {
  id?: Maybe<Scalars['String']>;
};


export class MutationRegisterInActivityArgs {
  id?: Maybe<Scalars['String']>;
};


export class MutationDeleteAssociationArgs {
  id: Scalars['String'];
};


export class MutationSaveAssociationArgs {
  association: AssociationInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationSaveAdministratorArgs {
  administrator?: Maybe<AdministratorInput>;
  user?: Maybe<Scalars['String']>;
};


export class MutationDeleteAdministratorArgs {
  id: Scalars['String'];
};


export class MutationSavePostArgs {
  post: PostInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationSaveCommentArgs {
  comment: CommentInput;
  post?: Maybe<Scalars['String']>;
};


export class MutationDeletePostArgs {
  id: Scalars['String'];
};


export class MutationDeleteMemberArgs {
  id: Scalars['String'];
};


export class MutationSaveMemberArgs {
  member: MemberInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationSaveMenuArgs {
  menu: MenuInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationDeleteMenuArgs {
  id: Scalars['String'];
};


export class MutationPayPaymentArgs {
  id: Scalars['String'];
};


export class MutationSaveUserArgs {
  user: UserInput;
  id?: Maybe<Scalars['String']>;
};


export class MutationAddUserArgs {
  username: Scalars['String'];
  email: Scalars['String'];
  new: Scalars['Boolean'];
};

export class PageActivities {
  totalElements?: Maybe<Scalars['Int']>;
  content: Array<Activity>;
};

export class PageAdministrators {
  totalElements?: Maybe<Scalars['Int']>;
  content?: Maybe<Array<Maybe<Administrator>>>;
};

export class PageAssociations {
  totalElements?: Maybe<Scalars['Int']>;
  content: Array<Association>;
};

export class PageMembers {
  totalElements: Scalars['Int'];
  content: Array<Member>;
};

export class PageMenu {
  totalElements: Scalars['Int'];
  content: Array<Menu>;
};

export class PagePayments {
  content: Array<Payment>;
  totalElements: Scalars['Int'];
};

export class PagePost {
  totalElements: Scalars['Int'];
  content: Array<Post>;
};

export class PageUser {
  content: Array<User>;
  totalElements: Scalars['Int'];
};

export class Pagination {
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  columns?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Filter>>;
};

export class Participant {
  user: User;
  payment: Payment;
};

export class Payment {
  id: Scalars['String'];
  name: Scalars['String'];
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  status: PaymentStatus;
  transaction: Scalars['String'];
};

export enum PaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  PaidManually = 'PAID_MANUALLY'
}

export class Post {
  id: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
  user: User;
  comments?: Maybe<Array<Maybe<Comment>>>;
  published: Scalars['DateTime'];
  tags: Array<Scalars['String']>;
};

export class PostInput {
  title: Scalars['String'];
  text: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export class Query {
  test?: Maybe<Scalars['String']>;
  findActivities: PageActivities;
  getActivity: Activity;
  getAssociation: Association;
  findAssociations: PageAssociations;
  findAllAssociations: Array<Association>;
  getAdministrator?: Maybe<Administrator>;
  findAdministrators?: Maybe<PageAdministrators>;
  findPosts: PagePost;
  getPost?: Maybe<Post>;
  getMember: Member;
  findMembers: PageMembers;
  getMenu: Menu;
  findMenus: PageMenu;
  checkPayment: Payment;
  findPayments?: Maybe<PagePayments>;
  findUsers?: Maybe<PageUser>;
  login: Array<Scalars['String']>;
  reloadToken: Array<Scalars['String']>;
};


export class QueryTestArgs {
  filter?: Maybe<Filter>;
  directive?: Maybe<DirectiveInput>;
  permissions?: Maybe<AdministratorInput>;
  concat?: Maybe<ContactInput>;
  family?: Maybe<FamilyMemberInput>;
  dish?: Maybe<DishInput>;
};


export class QueryFindActivitiesArgs {
  pagination?: Maybe<Pagination>;
};


export class QueryGetActivityArgs {
  id: Scalars['String'];
};


export class QueryGetAssociationArgs {
  id: Scalars['String'];
};


export class QueryFindAssociationsArgs {
  pagination: Pagination;
};


export class QueryGetAdministratorArgs {
  id?: Maybe<Scalars['String']>;
};


export class QueryFindAdministratorsArgs {
  pagination: Pagination;
};


export class QueryFindPostsArgs {
  pagination: Pagination;
};


export class QueryGetPostArgs {
  id?: Maybe<Scalars['String']>;
};


export class QueryGetMemberArgs {
  id: Scalars['String'];
};


export class QueryFindMembersArgs {
  pagination: Pagination;
};


export class QueryGetMenuArgs {
  id?: Maybe<Scalars['String']>;
};


export class QueryFindMenusArgs {
  pagination: Pagination;
};


export class QueryCheckPaymentArgs {
  id: Scalars['String'];
};


export class QueryFindPaymentsArgs {
  pagination?: Maybe<Pagination>;
};


export class QueryFindUsersArgs {
  pagination?: Maybe<Pagination>;
};


export class QueryLoginArgs {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export class User {
  id: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  name: Scalars['String'];
};

export class UserInput {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type ActivitiesTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ActivitiesTableQuery = { activities: (
    Pick<PageActivities, 'totalElements'>
    & { content: Array<(
      Pick<Activity, 'id' | 'date' | 'name' | 'price' | 'description' | 'maxParticipants' | 'registered'>
      & { payment?: Maybe<Pick<Payment, 'id' | 'status' | 'amount'>>, participants?: Maybe<Array<Maybe<{ user: Pick<User, 'username' | 'name'>, payment: Pick<Payment, 'status'> }>>> }
    )> }
  ) };

export type GetActivityQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActivityQuery = { activity: (
    Pick<Activity, 'id' | 'name' | 'description' | 'maxParticipants' | 'price' | 'date'>
    & { participants?: Maybe<Array<Maybe<{ user: Pick<User, 'username' | 'name'>, payment: Pick<Payment, 'status'> }>>> }
  ) };

export type SaveActivityMutationVariables = Exact<{
  activity: ActivityInput;
  id?: Maybe<Scalars['String']>;
}>;


export type SaveActivityMutation = { saveActivity?: Maybe<Pick<Activity, 'id'>> };

export type RegisterInActivityMutationVariables = Exact<{
  activity: Scalars['String'];
}>;


export type RegisterInActivityMutation = { registerInActivity: Pick<Payment, 'status'> };

export type AssociationsTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type AssociationsTableQuery = { associations: (
    Pick<PageAssociations, 'totalElements'>
    & { content: Array<(
      Pick<Association, 'id' | 'name' | 'telephone'>
      & { president?: Maybe<Pick<Directive, 'name'>> }
    )> }
  ) };

export type SaveAssociationMutationVariables = Exact<{
  association: AssociationInput;
  id?: Maybe<Scalars['String']>;
}>;


export type SaveAssociationMutation = { saveAssociation: Pick<Association, 'id'> };

export type GetAllAssociationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAssociationsQuery = { associations: Array<Pick<Association, 'id' | 'name' | 'payments'>> };

export type DeleteAssociationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAssociationMutation = { deleteAssociation: Pick<Association, 'id'> };

export type GetAssociationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAssociationQuery = { association: (
    Pick<Association, 'id' | 'cif' | 'name' | 'description' | 'email' | 'center' | 'census' | 'address' | 'telephone' | 'associationNumber' | 'stripe' | 'activeRoles' | 'inactiveRoles'>
    & { president?: Maybe<Pick<Directive, 'name' | 'email' | 'telephone' | 'identification'>>, secretary?: Maybe<Pick<Directive, 'name' | 'email' | 'telephone' | 'identification'>> }
  ) };

export type AddUserMutationVariables = Exact<{
  username: Scalars['String'];
  email: Scalars['String'];
  new: Scalars['Boolean'];
}>;


export type AddUserMutation = { addUser: Pick<User, 'id' | 'name' | 'username'> };

export type GetAdministratorsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type GetAdministratorsQuery = { administrators?: Maybe<(
    Pick<PageAdministrators, 'totalElements'>
    & { content?: Maybe<Array<Maybe<(
      Pick<Administrator, 'name' | 'lastName' | 'email' | 'phone'>
      & { user: Pick<User, 'id' | 'username'> }
    )>>> }
  )> };

export type GetAdministratorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdministratorQuery = { administrator?: Maybe<(
    Pick<Administrator, 'dni' | 'name' | 'lastName' | 'phone' | 'charge' | 'modified' | 'roles' | 'email'>
    & { author: Pick<User, 'username'>, user: Pick<User, 'id' | 'username'> }
  )> };

export type SaveAdministratorMutationVariables = Exact<{
  administrator?: Maybe<AdministratorInput>;
  user?: Maybe<Scalars['String']>;
}>;


export type SaveAdministratorMutation = { saveAdministrator: Pick<Administrator, 'modified'> };

export type DeleteAdministratorMutationVariables = Exact<{
  user: Scalars['String'];
}>;


export type DeleteAdministratorMutation = { admin: Pick<Administrator, 'dni'> };

export type PostTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type PostTableQuery = { posts: (
    Pick<PagePost, 'totalElements'>
    & { content: Array<(
      Pick<Post, 'id' | 'title' | 'published'>
      & { user: Pick<User, 'username' | 'id'> }
    )> }
  ) };

export type PostsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type PostsQuery = { posts: (
    Pick<PagePost, 'totalElements'>
    & { content: Array<Pick<Post, 'title' | 'text' | 'published' | 'tags' | 'id'>> }
  ) };

export type GetPostQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPostQuery = { post?: Maybe<Pick<Post, 'title' | 'published' | 'text' | 'tags' | 'id'>> };

export type PostDetailQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type PostDetailQuery = { post?: Maybe<(
    Pick<Post, 'title' | 'published' | 'text' | 'tags' | 'id'>
    & { comments?: Maybe<Array<Maybe<(
      Pick<Comment, 'date' | 'text' | 'id'>
      & { user: Pick<User, 'username'> }
    )>>> }
  )> };

export type SavePostMutationVariables = Exact<{
  post: PostInput;
  id?: Maybe<Scalars['String']>;
}>;


export type SavePostMutation = { savePost: Pick<Post, 'id'> };

export type SaveCommentMutationVariables = Exact<{
  post: Scalars['String'];
  comment: CommentInput;
}>;


export type SaveCommentMutation = { saveComment: Pick<Comment, 'id'> };

export type DeletePostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostMutation = { deletePost: Pick<Post, 'id'> };

export type TableMenusQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type TableMenusQuery = { menus: (
    Pick<PageMenu, 'totalElements'>
    & { content: Array<(
      Pick<Menu, 'date' | 'id'>
      & { dishes?: Maybe<Array<Maybe<Pick<Dish, 'category' | 'name' | 'notes'>>>> }
    )> }
  ) };

export type GetMenuQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMenuQuery = { menu: (
    Pick<Menu, 'date' | 'id'>
    & { dishes?: Maybe<Array<Maybe<Pick<Dish, 'category' | 'name' | 'notes'>>>> }
  ) };

export type SaveMenuMutationVariables = Exact<{
  menu: MenuInput;
  id?: Maybe<Scalars['String']>;
}>;


export type SaveMenuMutation = { menu: Pick<Menu, 'id'> };

export type MembersTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type MembersTableQuery = { members: (
    Pick<PageMembers, 'totalElements'>
    & { content: Array<(
      Pick<Member, 'id' | 'name' | 'active'>
      & { familyMembers: Array<Pick<FamilyMember, 'name'>>, user: Pick<User, 'id' | 'username'> }
    )> }
  ) };

export type GetMemberQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMemberQuery = { member: (
    Pick<Member, 'id' | 'name' | 'active' | 'roles'>
    & { contact: Pick<Contact, 'name' | 'lastName' | 'dni' | 'email' | 'phone'>, familyMembers: Array<Pick<FamilyMember, 'course' | 'name' | 'lastName'>>, user: Pick<User, 'username' | 'id'> }
  ) };

export type SaveMemberMutationVariables = Exact<{
  member: MemberInput;
  id?: Maybe<Scalars['String']>;
}>;


export type SaveMemberMutation = { saveMember: Pick<Member, 'id'> };

export type DeleteMemberMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMemberMutation = { deleteMember: Pick<Member, 'id'> };

export type PayPaymentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type PayPaymentMutation = { url: Mutation['payPayment'] };

export type CheckPaymentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CheckPaymentQuery = { payment: Pick<Payment, 'id' | 'status' | 'amount' | 'name'> };

export type TablePaymentQueryVariables = Exact<{
  pagination?: Maybe<Pagination>;
}>;


export type TablePaymentQuery = { payments?: Maybe<(
    Pick<PagePayments, 'totalElements'>
    & { content: Array<Pick<Payment, 'id' | 'name' | 'amount' | 'status' | 'transaction' | 'date'>> }
  )> };

export type UsersTableQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type UsersTableQuery = { users?: Maybe<(
    Pick<PageUser, 'totalElements'>
    & { content: Array<Pick<User, 'username'>> }
  )> };

export type LoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = Pick<Query, 'login'>;

export type ReloadTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ReloadTokenQuery = Pick<Query, 'reloadToken'>;

export const ActivitiesTableDocument = gql`
    query ActivitiesTable($pagination: Pagination!) {
  activities: findActivities(pagination: $pagination) {
    totalElements
    content {
      id
      date
      name
      price
      description
      maxParticipants
      registered
      payment {
        id
        status
        amount
      }
      participants {
        user {
          username
          name
        }
        payment {
          status
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivitiesTableQueryService extends Apollo.Query<ActivitiesTableQuery, ActivitiesTableQueryVariables> {
    document = ActivitiesTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActivityDocument = gql`
    query GetActivity($id: String!) {
  activity: getActivity(id: $id) {
    id
    name
    description
    maxParticipants
    price
    date
    participants {
      user {
        username
        name
      }
      payment {
        status
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActivityQueryService extends Apollo.Query<GetActivityQuery, GetActivityQueryVariables> {
    document = GetActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveActivityDocument = gql`
    mutation SaveActivity($activity: ActivityInput!, $id: String) {
  saveActivity(id: $id, activity: $activity) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveActivityMutationService extends Apollo.Mutation<SaveActivityMutation, SaveActivityMutationVariables> {
    document = SaveActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterInActivityDocument = gql`
    mutation RegisterInActivity($activity: String!) {
  registerInActivity(id: $activity) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterInActivityMutationService extends Apollo.Mutation<RegisterInActivityMutation, RegisterInActivityMutationVariables> {
    document = RegisterInActivityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssociationsTableDocument = gql`
    query AssociationsTable($pagination: Pagination!) {
  associations: findAssociations(pagination: $pagination) {
    totalElements
    content {
      id
      name
      telephone
      president {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssociationsTableQueryService extends Apollo.Query<AssociationsTableQuery, AssociationsTableQueryVariables> {
    document = AssociationsTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAssociationDocument = gql`
    mutation SaveAssociation($association: AssociationInput!, $id: String) {
  saveAssociation(association: $association, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAssociationMutationService extends Apollo.Mutation<SaveAssociationMutation, SaveAssociationMutationVariables> {
    document = SaveAssociationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllAssociationsDocument = gql`
    query GetAllAssociations {
  associations: findAllAssociations {
    id
    name
    payments
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllAssociationsQueryService extends Apollo.Query<GetAllAssociationsQuery, GetAllAssociationsQueryVariables> {
    document = GetAllAssociationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAssociationDocument = gql`
    mutation DeleteAssociation($id: String!) {
  deleteAssociation(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAssociationMutationService extends Apollo.Mutation<DeleteAssociationMutation, DeleteAssociationMutationVariables> {
    document = DeleteAssociationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAssociationDocument = gql`
    query GetAssociation($id: String!) {
  association: getAssociation(id: $id) {
    id
    cif
    name
    description
    email
    center
    census
    address
    telephone
    associationNumber
    stripe
    activeRoles
    inactiveRoles
    president {
      name
      email
      telephone
      identification
    }
    secretary {
      name
      email
      telephone
      identification
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAssociationQueryService extends Apollo.Query<GetAssociationQuery, GetAssociationQueryVariables> {
    document = GetAssociationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUserDocument = gql`
    mutation AddUser($username: String!, $email: String!, $new: Boolean!) {
  addUser(username: $username, email: $email, new: $new) {
    id
    name
    username
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUserMutationService extends Apollo.Mutation<AddUserMutation, AddUserMutationVariables> {
    document = AddUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAdministratorsDocument = gql`
    query GetAdministrators($pagination: Pagination!) {
  administrators: findAdministrators(pagination: $pagination) {
    totalElements
    content {
      name
      lastName
      email
      phone
      user {
        id
        username
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdministratorsQueryService extends Apollo.Query<GetAdministratorsQuery, GetAdministratorsQueryVariables> {
    document = GetAdministratorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAdministratorDocument = gql`
    query GetAdministrator($id: String!) {
  administrator: getAdministrator(id: $id) {
    dni
    name
    lastName
    phone
    charge
    modified
    roles
    email
    author {
      username
    }
    user {
      id
      username
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAdministratorQueryService extends Apollo.Query<GetAdministratorQuery, GetAdministratorQueryVariables> {
    document = GetAdministratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveAdministratorDocument = gql`
    mutation SaveAdministrator($administrator: AdministratorInput, $user: String) {
  saveAdministrator(user: $user, administrator: $administrator) {
    modified
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveAdministratorMutationService extends Apollo.Mutation<SaveAdministratorMutation, SaveAdministratorMutationVariables> {
    document = SaveAdministratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdministratorDocument = gql`
    mutation DeleteAdministrator($user: String!) {
  admin: deleteAdministrator(id: $user) {
    dni
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdministratorMutationService extends Apollo.Mutation<DeleteAdministratorMutation, DeleteAdministratorMutationVariables> {
    document = DeleteAdministratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostTableDocument = gql`
    query PostTable($pagination: Pagination!) {
  posts: findPosts(pagination: $pagination) {
    totalElements
    content {
      id
      title
      published
      user {
        username
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PostTableQueryService extends Apollo.Query<PostTableQuery, PostTableQueryVariables> {
    document = PostTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostsDocument = gql`
    query Posts($pagination: Pagination!) {
  posts: findPosts(pagination: $pagination) {
    totalElements
    content {
      title
      text
      published
      tags
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PostsQueryService extends Apollo.Query<PostsQuery, PostsQueryVariables> {
    document = PostsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPostDocument = gql`
    query GetPost($id: String!) {
  post: getPost(id: $id) {
    title
    published
    text
    tags
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPostQueryService extends Apollo.Query<GetPostQuery, GetPostQueryVariables> {
    document = GetPostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostDetailDocument = gql`
    query PostDetail($id: String) {
  post: getPost(id: $id) {
    title
    published
    text
    tags
    id
    comments {
      date
      text
      id
      user {
        username
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PostDetailQueryService extends Apollo.Query<PostDetailQuery, PostDetailQueryVariables> {
    document = PostDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SavePostDocument = gql`
    mutation SavePost($post: PostInput!, $id: String) {
  savePost(id: $id, post: $post) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SavePostMutationService extends Apollo.Mutation<SavePostMutation, SavePostMutationVariables> {
    document = SavePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveCommentDocument = gql`
    mutation SaveComment($post: String!, $comment: CommentInput!) {
  saveComment(post: $post, comment: $comment) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveCommentMutationService extends Apollo.Mutation<SaveCommentMutation, SaveCommentMutationVariables> {
    document = SaveCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePostDocument = gql`
    mutation deletePost($id: String!) {
  deletePost(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePostMutationService extends Apollo.Mutation<DeletePostMutation, DeletePostMutationVariables> {
    document = DeletePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TableMenusDocument = gql`
    query TableMenus($pagination: Pagination!) {
  menus: findMenus(pagination: $pagination) {
    totalElements
    content {
      date
      id
      dishes {
        category
        name
        notes
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TableMenusQueryService extends Apollo.Query<TableMenusQuery, TableMenusQueryVariables> {
    document = TableMenusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMenuDocument = gql`
    query GetMenu($id: String!) {
  menu: getMenu(id: $id) {
    date
    id
    dishes {
      category
      name
      notes
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMenuQueryService extends Apollo.Query<GetMenuQuery, GetMenuQueryVariables> {
    document = GetMenuDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveMenuDocument = gql`
    mutation SaveMenu($menu: MenuInput!, $id: String) {
  menu: saveMenu(menu: $menu, id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveMenuMutationService extends Apollo.Mutation<SaveMenuMutation, SaveMenuMutationVariables> {
    document = SaveMenuDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MembersTableDocument = gql`
    query MembersTable($pagination: Pagination!) {
  members: findMembers(pagination: $pagination) {
    totalElements
    content {
      id
      name
      active
      familyMembers {
        name
      }
      user {
        id
        username
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MembersTableQueryService extends Apollo.Query<MembersTableQuery, MembersTableQueryVariables> {
    document = MembersTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMemberDocument = gql`
    query GetMember($id: String!) {
  member: getMember(id: $id) {
    id
    name
    active
    roles
    contact {
      name
      lastName
      dni
      email
      phone
    }
    familyMembers {
      course
      name
      lastName
    }
    user {
      username
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMemberQueryService extends Apollo.Query<GetMemberQuery, GetMemberQueryVariables> {
    document = GetMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveMemberDocument = gql`
    mutation SaveMember($member: MemberInput!, $id: String) {
  saveMember(id: $id, member: $member) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveMemberMutationService extends Apollo.Mutation<SaveMemberMutation, SaveMemberMutationVariables> {
    document = SaveMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMemberDocument = gql`
    mutation DeleteMember($id: String!) {
  deleteMember(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMemberMutationService extends Apollo.Mutation<DeleteMemberMutation, DeleteMemberMutationVariables> {
    document = DeleteMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayPaymentDocument = gql`
    mutation PayPayment($id: String!) {
  url: payPayment(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayPaymentMutationService extends Apollo.Mutation<PayPaymentMutation, PayPaymentMutationVariables> {
    document = PayPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CheckPaymentDocument = gql`
    query CheckPayment($id: String!) {
  payment: checkPayment(id: $id) {
    id
    status
    amount
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckPaymentQueryService extends Apollo.Query<CheckPaymentQuery, CheckPaymentQueryVariables> {
    document = CheckPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TablePaymentDocument = gql`
    query TablePayment($pagination: Pagination) {
  payments: findPayments(pagination: $pagination) {
    totalElements
    content {
      id
      name
      amount
      status
      transaction
      date
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TablePaymentQueryService extends Apollo.Query<TablePaymentQuery, TablePaymentQueryVariables> {
    document = TablePaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersTableDocument = gql`
    query UsersTable($pagination: Pagination!) {
  users: findUsers(pagination: $pagination) {
    content {
      username
    }
    totalElements
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersTableQueryService extends Apollo.Query<UsersTableQuery, UsersTableQueryVariables> {
    document = UsersTableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    query Login($username: String!, $password: String!) {
  login(username: $username, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginQueryService extends Apollo.Query<LoginQuery, LoginQueryVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReloadTokenDocument = gql`
    query ReloadToken {
  reloadToken
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReloadTokenQueryService extends Apollo.Query<ReloadTokenQuery, ReloadTokenQueryVariables> {
    document = ReloadTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }