import {Component, Inject, OnInit} from '@angular/core';
import {CanteenService} from "../services/canteen.service";
import {Dish, Menu, MenuInput} from "../../../generated/graphql";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {convertArray} from "../../utils/forms-utils";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  errors: any;


  menu = new Menu();
  dishes = new Array<Dish>()


  constructor(
    private canteenService: CanteenService,
    private dialog: MatDialogRef<MenuComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

    this.menu.dishes = [];

    if (data.id)
      this.canteenService.get(data.id).subscribe(s => {
        this.menu = s.data.menu
        this.dishes = [...this.menu.dishes];
      });


  }

  ngOnInit(): void {


  }

  async save(menu: MenuInput) {


    convertArray(menu);

    const response = await this.canteenService.save(menu, this.menu.id).toPromise();

    this.errors = response.errors;

    if (response.data)
      this.dialog.close();

  }


  addDish() {
    this.dishes.push({notes: '', category: 1, name: ''});
  }

  delete(dish: number) {
    this.dishes.splice(dish, 1);
  }
}
