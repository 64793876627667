<mat-toolbar class="mat-elevation-z4">
  {{association.name }}
</mat-toolbar>

<div class="content">


  <mat-card>
    <mat-card-content>
      <div class="ck-content" [innerHTML]="association.description | safeHtml"></div>
      <div class="clear"></div>
    </mat-card-content>
  </mat-card>

</div>





