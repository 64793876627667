import {Pipe, PipeTransform} from '@angular/core';
import {Menu} from "../../../generated/graphql";

@Pipe({
  name: 'showDish'
})
export class ShowDishPipe implements PipeTransform {

  transform(menu: Menu, ...args: unknown[]): unknown {

    return menu.dishes.map( d => d.name).join(", ");
  }

}
