import {Pipe, PipeTransform} from '@angular/core';
import {DISHES} from "../CanteenConstants";

@Pipe({
  name: 'dishCategory'
})
export class DishCategoryPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {

    const dish = DISHES.find(d => d.value == value);

    return dish?.name;

  }

}
