import {Component, OnDestroy} from '@angular/core';
import {Association, GetAssociationQuery, GetAssociationQueryService} from "../../../generated/graphql";
import {AuthService} from "../../users/auth/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {

  association: GetAssociationQuery['association'] = new Association();
  private subscription: Subscription;


  constructor(
    private authService: AuthService,
    private router: Router,
    private associationService: GetAssociationQueryService
  ) {


    this.updateAssociation();

    this.subscription = this.router.events.subscribe( s => {
      if (s instanceof NavigationEnd)
        this.updateAssociation();
    });

  }

  private updateAssociation() {
    this.associationService
      .fetch({id: this.authService.active.association})
      .subscribe( s => this.association = s.data.association);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
