import {Component} from '@angular/core';
import {TableList} from "../../utils/TableList";
import {MatDialog} from "@angular/material/dialog";
import {PostComponent} from "../post/post.component";
import {DeletePostMutationService, PagePost, PostTableQuery, PostTableQueryService} from "../../../generated/graphql";

@Component({
  selector: 'app-posts-table',
  templateUrl: './posts-table.component.html',
  styleUrls: ['./posts-table.component.scss']
})
export class PostsTableComponent extends TableList {


  posts: PostTableQuery['posts'] = new PagePost();



  constructor(
    dialog: MatDialog,
    private postsTableService: PostTableQueryService,
    private deletePost: DeletePostMutationService,

  ) {
    super(PostComponent, dialog);
  }


  updateElements() {
    this.postsTableService
      .fetch({pagination: this.pagination})
      .subscribe( s => this.posts = s.data.posts);
  }

  delete(id: string): void {
    this.deletePost.mutate({id})
      .subscribe( s => this.updateElements());
  }

}
