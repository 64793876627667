


<h3>Comentarios</h3>

<mat-list dense>

  <mat-list-item *ngFor="let c of comments; let last = last">

    <img alt="avatar" src="https://picsum.photos/200?={{c.id}}" mat-list-avatar>

    <p mat-line>{{c.text}}</p>
    <div mat-line>{{ c.date | date: 'hh:mm dd/MM/yy'}} </div>

    <mat-divider *ngIf="!last"></mat-divider>

  </mat-list-item>


</mat-list>


