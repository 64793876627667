export class Profile {

  id = '';
  username = '';
  roles = '';
  association = '';
  associationName = '';
  payments = false;

}
